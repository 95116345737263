import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config/config.service';
import { LayoutService } from '../services/layout/layout.service';
import { Subscription,interval } from 'rxjs';
import { RouteInfo } from "./sidebar.metadata";
import { CustomTypesService } from '../services/custom-types/custom-types.service';


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon', {static: false}) toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = '../../assets/img/maw/maw_logo.png';
  public config: any = {};
  layoutSub: Subscription;
  showMenu : boolean = false ; 
  numberOfCustomTypes : number = 0 ; 
  menuSubscription : Subscription ; 


  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private layoutService: LayoutService,
    private _customTypesService : CustomTypesService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }

    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
      options => {
        if (options) {
          if (options.bgColor) {
            if (options.bgColor === 'white') {
              this.logoUrl = '../../assets/img/logo-dark.png';
            }
            else {
              this.logoUrl = '../../assets/img/logo.png';
            }
          }

          if (options.compactMenu === true) {
            this.expanded = false;
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = true;
          }
          else if (options.compactMenu === false) {
            this.expanded = true;
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = false;
          }

        }
      });

  }


  ngOnInit() {
    this.config = this.configService.templateConf;
    this.menuItems = ROUTES;
    this.subscribeOnGetCustomTypesObservable() ; 
    
    /*this.menuSubscription = interval(5000).subscribe(
      () => {
        //this.subscribeOnGetCustomTypesObservable() ; 
      }
    ); */

    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = '../../assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = '../../assets/img/logo.png';
    }


  }

  subscribeOnGetCustomTypesObservable() {
    this._customTypesService.getList().subscribe(
      (result) => {
        this.numberOfCustomTypes = result.custom_types.length ; 
        for(let i=0 ; i<result.custom_types.length;i++) {
          let routeObject = {
              path: '', title: result.custom_types[i].label, icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
                  { path: '/home/custom-type-content/'+result.custom_types[i].id+'/create', title: 'Ajouter', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                  { path: '/home/ct-contents-categories/'+result.custom_types[i].id+'/index', title: 'Catégories', icon: 'ft-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                  { path: '/home/custom-type-content/'+result.custom_types[i].id+'/index', title: result.custom_types[i].label+'s', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              ]
          } ;
          //console.log(routeObject) ; 
          ROUTES.splice(3,0,routeObject) ;
        }
        

        this.showMenu = true ;
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ;   
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        }
        else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          //this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          //this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
        }
      }
    }, 0);


  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    ROUTES.splice(3,this.numberOfCustomTypes) ; 
    this.numberOfCustomTypes = 0 ; 
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
}
