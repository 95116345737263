import { Component, OnInit } from '@angular/core';
import { TopMenuElement } from 'src/app/models/home-page/top-menu-element.model';
import { CommonService } from 'src/app/services/common/common.service';
import { TopMenuService } from 'src/app/services/home-page/top-menu/top-menu.service';

@Component({
  selector: 'app-top-menu-elements-index',
  templateUrl: './top-menu-elements-index.component.html',
  styleUrls: ['./top-menu-elements-index.component.scss']
})
export class TopMenuElementsIndexComponent implements OnInit {

  elements : TopMenuElement[] = null ; 
  searchWord : string = '' ;
  

  constructor(
    private _elementsService : TopMenuService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnGetElementsObservable() ; 
  }

  subscribeOnGetElementsObservable() {
     this._elementsService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.elements = result.elements ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
     ) ; 
  }

  filteredElementsArray() {
    return this._commonService.filterArray(this.searchWord,this.elements) ; 
  }

}
