import { Component, OnInit } from '@angular/core';
import { SliderContent } from 'src/app/models/home-page/slider-content.model';
import { SliderService } from 'src/app/services/home-page/slider/slider.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-slider-index',
  templateUrl: './slider-index.component.html',
  styleUrls: ['./slider-index.component.scss']
})
export class SliderIndexComponent implements OnInit {

  sliderContents : SliderContent[] = null ; 
  constructor(
    private _sliderService : SliderService , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetContentsObservable();
  }

  subscribeOnGetContentsObservable() {
    this._sliderService.getList().subscribe(
      (result) => {
        this.sliderContents = result.contents ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
