import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HeadingItem } from 'src/app/models/heading-item.model';
import { CommonService } from 'src/app/services/common/common.service';
import { HeadingItemsService } from 'src/app/services/heading-items/heading-items.service';

@Component({
  selector: 'app-heading-items-edit',
  templateUrl: './heading-items-edit.component.html',
  styleUrls: ['./heading-items-edit.component.scss']
})
export class HeadingItemsEditComponent implements OnInit {

  itemId : string = '' ; 
  item : HeadingItem = null ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _itemsService : HeadingItemsService,
    private _router : Router , 
    private _toastr : ToastrService,
    private _formBuilder : FormBuilder , 
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.itemId = params['id'] ; 
        this.subscribeOnGetItemObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetItemObservable() {
    this._itemsService.getItemById(this.itemId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.item = result.item ; 
          this.initEditForm() ; 
        } else if(result.success_code == 404) {
          this._router.navigate(['/home','headings','index']) ; 
          this._toastr.error(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.item.title,[Validators.required]] , 
      content : [this.item.content,[Validators.required]] , 
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 

  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onFileChange(event,fileBrowser) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl) {
    let editorValue = this.getControlValue('content') ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ;
    let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px !important; width:100% !important" />' ;
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl('content').setValue(finalEditorValue) ; 
  }

  onItemUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      title : this.getControlValue('title') ,
      content : this.getControlValue('content') ,
    }
    this.formIsSubmitted = true ; 
    this.subscribeOnItemUpdateObservable(request,this.itemId) ; 
  }

  subscribeOnItemUpdateObservable(request : any , id : string) {
    this._itemsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','heading-items',this.item.heading_id,'index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
