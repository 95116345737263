import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PageCategory } from 'src/app/models/page-category.model';
import { CommonService } from 'src/app/services/common/common.service';
import { PagesCategoriesService } from 'src/app/services/pages-categories/pages-categories.service';
import { PagesService } from 'src/app/services/pages/pages.service';

@Component({
  selector: 'app-pages-create',
  templateUrl: './pages-create.component.html',
  styleUrls: ['./pages-create.component.scss']
})
export class PagesCreateComponent implements OnInit {

  _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 
  categories : PageCategory[] = null ; 
  generatedSlug : string = '' ; 

  constructor(
    private _pagesService  : PagesService,
    private _router : Router,
    private _toastr : ToastrService,
    private _formBuilder : FormBuilder,
    private _categoriesService : PagesCategoriesService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnGetCategoriesObservable()  ;
  }

  subscribeOnGetCategoriesObservable() {
    this._categoriesService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.categories = result.categories ; 
          this.initCreateForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initCreateForm() {
    let group = {
      title : ['',[Validators.required]] , 
      //display_title : ['',[Validators.required]] , 
      description : ['',Validators.required] , 
      content : ['',Validators.required] , 
      meta_title : [''] , 
      meta_description : [''] , 
      keywords : [''] , 
      categories: new FormArray([],Validators.required),
    } ; 
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onCheck(event) {
    const formArray = this.getControl('categories') as FormArray ; 
    //formArray.push(new FormControl(event.target.value)) ;
    if(event.target.checked) {
      formArray.push(new FormControl(event.target.value)) ;
    } else {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value === event.target.value) {
            formArray.removeAt(index) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
    }
    console.log(this.getControl('categories').value) ; 
  }

  onPageCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      title : this.getControlValue('title') , 
      //display_title : this.getControlValue('display_title') , 
      description : this.getControlValue('description') ,
      content : this.getControlValue('content') , 
      meta_title : this.getControlValue('meta_title') , 
      meta_description : this.getControlValue('meta_description')  , 
      keywords : this.getControlValue('keywords') , 
      categories : this.getControlValue('categories') 
    } ;
    this.formIsSubmitted = true ; 
    this.subscribeOnPageStoreObservable(request) ;  
  }

  subscribeOnPageStoreObservable(request : any) {
    this._pagesService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','pages','index']) ;
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onFileChange(event,fileBrowser) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl) {
    let editorValue = this.getControlValue('content') ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ; 
    let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px !important; width:100% !important" />' ; 
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl('content').setValue(finalEditorValue) ; 
  }

  domain() {
    return this._pagesService.siteDomain ; 
  }

  onUrlTitleChange(event) {
    if(event.target.value.trim().length == 0) {
      this.generatedSlug = '' ; 
      return 0 ; 
    } 
    this.getGeneratedSlug(event.target.value) ; 
  }
  getGeneratedSlug(text : string) {
    this._commonService.getSlug(text).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.generatedSlug = result.slug ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
