import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import { Training } from '../models/training.model';
import { DashboardService } from '../services/dashboard/dashboard.service';

declare var require: any;

const data: any = require('./chartist.json');

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  // donuts dashboard 
  donutDashboard =  {
    "series": [
      
    ]
  } ;

        // Donut chart configuration Starts
        DonutChart: Chart = {
          type: 'Pie',
          data: this.donutDashboard,
          options: {
              donut: true,
              startAngle: 0,
              labelInterpolationFnc: function (value) {
                
                  var total = data['donutDashboard'].series.reduce(function (prev, series) {
                      return prev + series.value;
                  }, 0);
                  return total + '%';
              }
          },
          events: {
              draw(data: any): void {
                  if (data.type === 'label') {
                      if (data.index === 0) {
                          data.element.attr({
                              dx: data.element.root().width() / 2,
                              dy: data.element.root().height() / 2
                          });
                      } else {
                          data.element.remove();
                      }
                  }
  
              }
          }
      };
  //end donuts
  
  
      // line chart configuration Starts
      WidgetlineChart: Chart = {
        type: 'Line', data: data['WidgetlineChart'],
        options: {
            axisX: {
                showGrid: true,
                showLabel: false,
                offset: 0,
            },
            axisY: {
                showGrid: false,
                low: 40,
                showLabel: false,
                offset: 0,
            },
            lineSmooth: Chartist.Interpolation.cardinal({
                tension: 0
            }),
            fullWidth: true,
        },
    };
    // Line chart configuration Ends

    show : boolean = false ; 

    numberOfPages : number = null ; 
    numberOfRhTrainings : number = null ; 
    numberOfDigitalTrainings : number = null ; 
    numberOfBtsTrainings : number = null ; 

    total_number_of_contacts : number = null ; 
    callback_requests_number : number = null ; 
    registrations_number : number = null ; 

    topFiveTrainings : Training[] = null ; 

    newsletters_number : number = null ; 
    faq_number : number = null ; 

  constructor(
    private _dashboardService : DashboardService
  ) { }

  ngOnInit() {
    this.subscribeOnGetDashboardObservable() ; 
  }

  subscribeOnGetDashboardObservable() {
    this._dashboardService.dashboard().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.numberOfPages = result.pages ; 
          this.numberOfRhTrainings = result.rh ; 
          this.numberOfDigitalTrainings = result.digital ; 
          this.numberOfBtsTrainings = result.bts ;

          this.total_number_of_contacts = result.contacts_total_number ;
          this.callback_requests_number = result.callback_requests_number ; 
          this.registrations_number = result.registrations_number ; 
          this.initDonut() ; 

          this.topFiveTrainings = result.top_five_trainings ; 

          this.newsletters_number = result.newsletters_number ; 
          this.faq_number = result.faq_number ; 
          
          this.show = true ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initDonut() {
    let requests = {
      "name": "done",
      "className": "ct-done",
      "value": this.callback_requests_number  , 
    }; 
    let registrations = {
      "name": "progress",
      "className": "ct-progress",
      "value": this.registrations_number
    } ; 
    this.donutDashboard.series.push(requests) ; 
    this.donutDashboard.series.push(registrations) ; 
  }

}
