import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterBlocElement } from 'src/app/models/home-page/footer-bloc-element.model';
import { FooterBloc } from 'src/app/models/home-page/footer-bloc.model';
import { CommonService } from 'src/app/services/common/common.service';
import { FooterBlocsElementsService } from 'src/app/services/home-page/footer-blocs-elements/footer-blocs-elements.service';
import { FooterBlocsService } from 'src/app/services/home-page/footer-blocs/footer-blocs.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-footer-elements-index',
  templateUrl: './footer-elements-index.component.html',
  styleUrls: ['./footer-elements-index.component.scss']
})
export class FooterElementsIndexComponent implements OnInit {

  blocId : string = '' ; 
  bloc : FooterBloc = null ;
  elements : FooterBlocElement[] = null ; 
  searchWord : string = '' ;  

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _blocsService : FooterBlocsService,
    private _router : Router,
    private _toastr : ToastrService ,
    private _elementsService : FooterBlocsElementsService,
    private _commonService : CommonService 
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.blocId = params['blocId'] ; 
        this.subscribeOnGetBlocObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    );
  }

  subscribeOnGetBlocObservable() {
    this._blocsService.getBlocById(this.blocId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','footer-blocs','index']) ; 
          this._toastr.error(result.message) ;  
        } else if(result.success_code == 200 ) {
          this.bloc = result.bloc ; 
          this.subscribeOnGetElementObservable() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetElementObservable() {
    this._elementsService.getList(this.blocId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.elements = result.elements; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this._commonService.filterArrayByTitleName(this.searchWord,this.elements,'content') ;  
  }

  onElementDelete(id:string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cet élément ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let element = this.elements.find(
          (e) => {
            return e.id == +id ; 
          }
        ) ; 
        this.elements.splice(this.elements.indexOf(element),1) ;
        this.subscribeOnElementDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnElementDeleteObservable(id : string) {
    this._elementsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
