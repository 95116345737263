import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterBloc } from 'src/app/models/home-page/footer-bloc.model';
import { FooterBlocsService } from 'src/app/services/home-page/footer-blocs/footer-blocs.service';

@Component({
  selector: 'app-footer-blocs-create',
  templateUrl: './footer-blocs-create.component.html',
  styleUrls: ['./footer-blocs-create.component.scss']
})
export class FooterBlocsCreateComponent implements OnInit {

  private _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ;
  blocs : FooterBloc[] = null ; 
  show : boolean = false ;  


  constructor(
    private _footerBlocsService : FooterBlocsService,
    private _formBuilder : FormBuilder, 
    private _toastr : ToastrService,
    private _router : Router
  ) { }

  ngOnInit() {
    //this.initCreateForm() ;
    this.subscribeOnGetBlocsObservable() ;  
  }

  initCreateForm() {
    let group = {
      title : ['',[Validators.required]] , 
      type : [0,[Validators.required]]
    } ; 
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onBlocCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ;
      return 0 ;  
    }
    let request =  {
      title : this.getControlValue('title') ,
      type : this.getControlValue('type') ,
    } ;
    this.formIsSubmitted = true ; 
    this.subscribeOnStoreBlocObservable(request) ;  
  }

  subscribeOnStoreBlocObservable(request : any) {
    this._footerBlocsService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','footer-blocs','index']) ;
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;  
        }
      } ,
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetBlocsObservable() {
    this._footerBlocsService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.blocs = result.blocs ; 
          if(this.blocs.length < 4) {
            this.initCreateForm() ; 
            this.show = true ; 
          } else {
            this._router.navigate(['/home','home-page','footer-blocs','index']) ;
            this._toastr.error('Vous avez atteint votre limite de création des blocs !') ;  
          }
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
