import { Component, OnInit } from '@angular/core';
import { International } from 'src/app/models/home-page/international.model';
import { InternationalService } from 'src/app/services/home-page/international/international.service';

@Component({
  selector: 'app-international-index',
  templateUrl: './international-index.component.html',
  styleUrls: ['./international-index.component.scss']
})
export class InternationalIndexComponent implements OnInit {

  internationals : International[] = null ;

  constructor(
    private _internationalsService : InternationalService
  ) { }

  ngOnInit() {
    this.subscribeOnGetInternationalsObservable();
  }

  subscribeOnGetInternationalsObservable() {
    this._internationalsService.getList().subscribe(
      (result) => {
        this.internationals = result.internationals ; 
      } , 
      (error) => {
        console.log(error) ;
      }
    ) ; 
  }

}
