import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router : Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(localStorage.getItem('token') != null && 
       localStorage.getItem('token') != "" && 
       localStorage.getItem('owner') != null && 
       localStorage.getItem('owner') != ""
       ) {
      return true ; 
    } else {
      this._router.navigate(['/auth','login']) ; 
       
      
    }
  }
  
}
