import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class FooterBlocsService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
  }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'footer-blocs/get-list',{headers :tokenHeader}) ; 
  }

  getBlocById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'footer-blocs/'+id+'/get-bloc',{headers : tokenHeader}) ; 
  }

  store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'footer-blocs/store',request,{headers : tokenHeader}) ; 
  }

  update(request : any,id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'footer-blocs/'+id+'/update',request,{headers : tokenHeader}) ; 
  }

  delete(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'footer-blocs/'+id+'/delete',{headers : tokenHeader}) ;
  }
}
