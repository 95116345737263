import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
   }

  updatePassword(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'owners/update-password',request,{headers : tokenHeader}) ; 
  }
}
