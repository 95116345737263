import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Training } from 'src/app/models/training.model';
import { TrainingsService } from 'src/app/services/trainings/trainings/trainings.service';

@Component({
  selector: 'app-training-presentation-edit',
  templateUrl: './training-presentation-edit.component.html',
  styleUrls: ['./training-presentation-edit.component.scss']
})
export class TrainingPresentationEditComponent implements OnInit {

  trainingId : string = '' ;
  training : Training = null ;  
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _formBuilder : FormBuilder , 
    private _trainingsService : TrainingsService ,
    private _toastr :ToastrService,
    private _router : Router
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.trainingId = params['trainingId'] ; 
        this.subscribeOnGetTrainingObservable() ; 
      } ,
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTrainingObservable() {
    this._trainingsService.getTrainingById(this.trainingId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.training = result.education ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      first_bloc_title : [this.training.first_bloc_title,[Validators.required]] , 
      first_bloc_description : [this.training.first_bloc_description,[Validators.required]], 
      first_bloc_image : '' ,
      first_bloc_alt_image : [this.training.first_bloc_alt_image] , 
      second_bloc_title : [this.training.second_bloc_title,[Validators.required]] ,
      second_bloc_description : [this.training.second_bloc_description,[Validators.required]] , 
      second_bloc_image: '', 
      second_bloc_alt_image : [this.training.second_bloc_alt_image] , 
      meta_title : [this.training.meta_title] , 
      meta_description : [this.training.meta_description] , 
      keywords : [this.training.keywords] , 
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onPresentationUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 
    let first_bloc_title = this.getControlValue('first_bloc_title') ; 
    let first_bloc_description = this.getControlValue('first_bloc_description') ; 
    let first_bloc_image = this.getControlValue('first_bloc_image') ; 
    let second_bloc_title = this.getControlValue('second_bloc_title') ; 
    let second_bloc_description = this.getControlValue('second_bloc_description') ; 
    let second_bloc_image = this.getControlValue('second_bloc_image') ; 
    let meta_title = this.getControlValue('meta_title') ; 
    let meta_description = this.getControlValue('meta_description') ; 
    let keywords = this.getControlValue('keywords') ; 
    let first_bloc_alt_image = this.getControlValue('first_bloc_alt_image') ; 
    let second_bloc_alt_image = this.getControlValue('second_bloc_alt_image') ; 

    formData.append('first_bloc_title',first_bloc_title) ; 
    formData.append('first_bloc_description' , first_bloc_description) ;
    formData.append('first_bloc_image',first_bloc_image) ; 
    formData.append('second_bloc_title',second_bloc_title) ; 
    formData.append('second_bloc_description',second_bloc_description) ; 
    formData.append('second_bloc_image',second_bloc_image) ; 
    formData.append('meta_title',meta_title) ; 
    formData.append('meta_description',meta_description) ; 
    formData.append('keywords' , keywords) ; 
    formData.append('first_bloc_alt_image',first_bloc_alt_image) ; 
    formData.append('second_bloc_alt_image',second_bloc_alt_image) ; 
    
    this.formIsSubmitted = true ; 
    this.subscribeOnPresentationUpdateObservable(formData,this.trainingId) ; 

  }

  onImagesChange(event,image,index) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;
      if(index == 1) {
        this._editForm.patchValue({
          first_bloc_image : file ,
        }) ;
      } else {
        this._editForm.patchValue({
          second_bloc_image : file ,
        }) ;
      }  
      
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ;   
    }
  }

  onCopy() {
    this._toastr.success('Lien copié !') ; 
  }

  subscribeOnPresentationUpdateObservable(request : any , id : string) {
    this._trainingsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','training-contents',this.training.id,'index']) ;
          //this.training = result.education ; 
          //this.initEditForm() ;  
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
