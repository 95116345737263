import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterBlocElement } from 'src/app/models/home-page/footer-bloc-element.model';
import { FooterBloc } from 'src/app/models/home-page/footer-bloc.model';
import { FooterBlocsElementsService } from 'src/app/services/home-page/footer-blocs-elements/footer-blocs-elements.service';
import { FooterBlocsService } from 'src/app/services/home-page/footer-blocs/footer-blocs.service';

@Component({
  selector: 'app-footer-elements-edit',
  templateUrl: './footer-elements-edit.component.html',
  styleUrls: ['./footer-elements-edit.component.scss']
})
export class FooterElementsEditComponent implements OnInit {

  element : FooterBlocElement = null ; 
  bloc : FooterBloc = null  ; 
  elementId : string = '' ; 
  private _editForm : FormGroup ;
  formIsSubmitted : boolean = false ; 

  constructor(
    private _elementsService : FooterBlocsElementsService,
    private _blocsService : FooterBlocsService,
    private _activatedRoute : ActivatedRoute,
    private _router : Router,
    private _toastr : ToastrService,
    private _formBuilder : FormBuilder
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.elementId = params['id'] ; 
        this.subscribeOnGetElementObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetElementObservable() {
    this._elementsService.getElementById(this.elementId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','footer-blocs','index']) ;
          this._toastr.error(result.message) ;  
        } else if(result.success_code == 200) {
          this.element = result.element ; 
          this.subscribeOnGetBlocObservable(this.element.footer_bloc_id) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetBlocObservable(blocId) {
    this._blocsService.getBlocById(blocId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.bloc = result.bloc ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {} ; 
    if(this.bloc.type == 0) {
      group = {
        content : [this.element.content,[Validators.required]] , 
        url : [this.element.url,[Validators.required]]
      } ; 
    } else if(this.bloc.type ==1) {
      group = {
        content : [this.element.content,[Validators.required]]
      }
    }
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onElementUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {} ; 
    if(this.bloc.type == 0) {
      request = {
        content : this.getControlValue('content') ,
        url : this.getControlValue('url') ,
      } ; 
    } else if(this.bloc.type == 1) {
      request = {
        content: this.getControlValue('content') 
      } ;
    }
    this.formIsSubmitted = true ; 
    this.subscribeOnElementUpdateObservable(request,this.elementId) ; 
  }

  subscribeOnElementUpdateObservable(request : any,id:string) {
    this._elementsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200){
          this._router.navigate(['/home','home-page','footer-elements',this.bloc.id,'index']) ;
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
