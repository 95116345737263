import { Component, OnInit } from '@angular/core';
import { TrainingType } from 'src/app/models/home-page/training-type.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TrainingTypesService } from 'src/app/services/home-page/training-types/training-types.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-types-edit',
  templateUrl: './training-types-edit.component.html',
  styleUrls: ['./training-types-edit.component.scss']
})
export class TrainingTypesEditComponent implements OnInit {

  trainingType : TrainingType = null ; 
  trainingTypeId : string = '' ; 
  private _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _formBuilder : FormBuilder , 
    private _router : Router , 
    private _typesService : TrainingTypesService ,
    private _activatedRoute : ActivatedRoute , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.trainingTypeId = params['id'] ;
        this.subscribeOnGetTrainingTypeObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ); 
  }

  subscribeOnGetTrainingTypeObservable() {
    this._typesService.getTtrainingTypeById(this.trainingTypeId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','training-types','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.trainingType = result.type ;
          //console.log(this.trainingType) ;
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ;
  }

  initEditForm() {
    let group = {
      title : [this.trainingType.title,[Validators.required]] , 
      text : [this.trainingType.text] , 
      url : [this.trainingType.url] , 
      image : '' ,
      alt_image : [this.trainingType.alt_image] , 
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this._editForm.get(controlName).value ; 
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._editForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ;   
    }
  }

  onTypeUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide ! ') ; 

      return 0 ; 
    }
    const formData = new FormData() ; 
    let title = this.getControlValue('title') ; 
    let text = this.getControlValue('text') ; 
    let url = this.getControlValue('url') ; 
    let image = this.getControlValue('image') ; 
    let alt_image = this.getControlValue('alt_image') ; 
    formData.append('title',title) ; 
    formData.append('text',text) ; 
    formData.append('url',url) ; 
    formData.append('image',image) ;
    formData.append('alt_image',alt_image) ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnTypeUpdateObservable(formData,this.trainingTypeId) ;
  }

  subscribeOnTypeUpdateObservable(request : any , id : string) {
    this._typesService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','training-types','index']) ;
          this.formIsSubmitted = false; 
          this._toastr.success(result.message) ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
