import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterBloc } from 'src/app/models/home-page/footer-bloc.model';
import { FooterBlocsService } from 'src/app/services/home-page/footer-blocs/footer-blocs.service';

@Component({
  selector: 'app-footer-blocs-edit',
  templateUrl: './footer-blocs-edit.component.html',
  styleUrls: ['./footer-blocs-edit.component.scss']
})
export class FooterBlocsEditComponent implements OnInit {

  _editForm : FormGroup ; 
  bloc : FooterBloc  ; 
  blocId : string = '' ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _formBuilder : FormBuilder,
    private _blocsService : FooterBlocsService,
    private _activatedRoute : ActivatedRoute,
    private _router : Router , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.blocId = params['id'] ; 
        this.subscribeOnGetBlocObservable() ;
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetBlocObservable() {
    this._blocsService.getBlocById(this.blocId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','footer-blocs','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.bloc = result.bloc ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.bloc.title,[Validators.required]] , 
      type : [this.bloc.type,[Validators.required]]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onBlocUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      title : this.getControlValue('title') , 
      type : this.getControlValue('type') , 
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnBlocUpdateObservable(request,this.blocId) ; 
  }

  subscribeOnBlocUpdateObservable(request : any,id : string) {
    this._blocsService.update(request,id).subscribe(
      (result) => { 
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','footer-blocs','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
