import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PagesCategoriesService } from 'src/app/services/pages-categories/pages-categories.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pages-categories-create',
  templateUrl: './pages-categories-create.component.html',
  styleUrls: ['./pages-categories-create.component.scss']
})
export class PagesCategoriesCreateComponent implements OnInit {

   _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _formBuilder : FormBuilder,
    private _categoriesService : PagesCategoriesService,
    private _toastr : ToastrService,
    private _router : Router
    ) { }

  ngOnInit() {
    this.initCreateForm() ; 
  }

  initCreateForm() {
    let group = {
      label : ['',[Validators.required]]
    } ;
    this._createForm = this._formBuilder.group(group) ; 
  }

  onCategoryCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ;  
      return 0 ; 
    }

    let request = {
      label : this.getControlValue('label') 
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnStoreCategoryObservable(request) ; 

  }

  subscribeOnStoreCategoryObservable(request : any) {
    this._categoriesService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','pages-categories','index']) ; 
          this._toastr.success(result.message) ; 
        }
        this.formIsSubmitted = false ; 
      } ,
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  getControlValue(controlName : string) {
    return this._createForm.get(controlName).value ; 
  }

  getControl(controlName : string) {
    return this._createForm.get(controlName) ; 
  }

}
