import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HomePageMeta } from 'src/app/models/home-page/home-page-meta.model';
import { SliderService } from 'src/app/services/home-page/slider/slider.service';

@Component({
  selector: 'app-home-page-meta-edit',
  templateUrl: './home-page-meta-edit.component.html',
  styleUrls: ['./home-page-meta-edit.component.scss']
})
export class HomePageMetaEditComponent implements OnInit {

  metaId : string = '' ; 
  meta : HomePageMeta  = null ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _formBuilder : FormBuilder , 
    private _slidersService : SliderService , 
    private _toastr : ToastrService , 
    private _router : Router , 
    private _activatedRoute : ActivatedRoute , 
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.metaId = params['id'] ;
        this.subscribeOnGetMetaDataObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetMetaDataObservable() {
    this._slidersService.getHomePageMetaDataById(this.metaId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.meta = result.meta ; 
          this.initEditForm() ; 
        } else if(result.success_code == 404) {
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.error(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      meta_title : [this.meta.meta_title] , 
      meta_description : [this.meta.meta_description] , 
      keywords : [this.meta.keywords]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onMetaDataUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      meta_title :this.getControlValue('meta_title') , 
      meta_description : this.getControlValue('meta_description') , 
      keywords : this.getControlValue('keywords')
    }
    this.formIsSubmitted = true ; 
    this.subscribeOnMetaDataUpdateObservable(request,this.metaId) ; 
  }

  subscribeOnMetaDataUpdateObservable(request : any , id : string) {
    this._slidersService.updateHomePageMetaData(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.meta = result.meta ; 
          this.formIsSubmitted = false ; 
          this.initEditForm() ; 
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
