import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomTypesService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
  }


  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'custom-types/get-list',{headers : tokenHeader}) ; 
  }

  getCustomTypeById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'custom-types/'+id+'/get-custom-type',{headers : tokenHeader}) ; 
  }


  store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'custom-types/store',request,{headers : tokenHeader}) ; 
  }

  update(request : any,id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'custom-types/'+id+'/update',request,{headers : tokenHeader}) ; 
  }

  delete(id:string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'custom-types/'+id+'/delete',{headers : tokenHeader}) ; 
  }
}
