import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomTypeCategory } from 'src/app/models/custom-type-category.model';
import { ToastrService } from 'ngx-toastr';
import { CustomTypesCategoriesService } from 'src/app/services/custom-types-categories/custom-types-categories.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-custom-types-categories-edit',
  templateUrl: './custom-types-categories-edit.component.html',
  styleUrls: ['./custom-types-categories-edit.component.scss']
})
export class CustomTypesCategoriesEditComponent implements OnInit {

  private _editForm : FormGroup ; 
  categoryId : string ; 
  category : CustomTypeCategory  = null ; 
  formIsSubmitted : boolean = false ;
  
  constructor(
    private _formBuilder : FormBuilder , 
    private _toastr : ToastrService , 
    private _categoriesService : CustomTypesCategoriesService,
    private _activatedRoute : ActivatedRoute,
    private _router : Router
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ;
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.categoryId = params['id'] ;
        this.subscribeOnGetCategoryObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetCategoryObservable() {
    this._categoriesService.getCategoryById(this.categoryId).subscribe(
      (result) => {
        console.log(result.success_code) ; 
        if(result.success_code == 404) {
          this._router.navigate(['/home','custom-types-categories','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.category = result.category ;
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    )
  }

  initEditForm() {
    let group = {
      label : [this.category.label,[Validators.required]] ,
    } ; 

    this._editForm = this._formBuilder.group(group) ; 
  }

  onCategoryUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }

    let request = {
      label : this.getControlValue('label') ,
    } ; 

    this.formIsSubmitted = true ; 
    this.subscribeOnCategoryUpdateObservable(request,this.categoryId) ; 
  }

  getControlValue(controlName : string) {
    return this._editForm.get(controlName).value ; 
  }

  getControl(controlName : string) {
    return this._editForm.get(controlName) ; 
  }

  subscribeOnCategoryUpdateObservable(request : any , id : string) {
    this._categoriesService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','custom-types-categories','index']) ;
          this.formIsSubmitted = false; 
          this._toastr.success(result.message) ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
