import {Component, QueryList, ViewChildren,OnInit} from '@angular/core';
import {CustomTypeCategory} from '../../models/custom-type-category.model';
import {NgbdSortableHeader, SortEvent} from '../../directives/sortable.directive';
import { CustomTypesCategoriesService } from 'src/app/services/custom-types-categories/custom-types-categories.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Component({
  selector: 'app-custom-types-categories-index',
  templateUrl: './custom-types-categories-index.component.html',
  styleUrls: ['./custom-types-categories-index.component.scss']
})
export class CustomTypesCategoriesIndexComponent implements OnInit {

  categories : CustomTypeCategory[] = null; 
  searchWord : string = '' ; 

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  constructor(
    private _categoriesService : CustomTypesCategoriesService , 
    private _commonService : CommonService , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetCategoriesListObservable() ; 
  }

  subscribeOnGetCategoriesListObservable() {
    return this._categoriesService.getList().subscribe(
      (result) => {
        this.categories = result.categories ; 
      } , 
      (error) => {
        console.log(error);
      }
    ) ; 
  }

  filteredCategoriesArray() {
    if(this.searchWord == '') {
      return this.categories ; 
    } else {
      return this._commonService.filterArray(this.searchWord,this.categories) ; 
    }
  }

  onCategoryDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cette catégorie ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let cat = this.categories.find(
          (category) => {
            return category.id == +id ; 
          }
        ) ; 
        this.categories.splice(this.categories.indexOf(cat),1) ;
        this.subscribeOnDeleteCategoryObservable(id) ; 
      }
    });
  }

  subscribeOnDeleteCategoryObservable(id : string) {
    this._categoriesService.delete(id).subscribe(
      (result)=> {
        if(result.success_code == 200) {
          this._toastr.error(result.message) ;
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    )
  }

}
