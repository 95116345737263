import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SliderService extends BaseService {

  constructor(private _http : HttpClient) { 
    super() ; 
  }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'slider/get-list',{headers : tokenHeader}) ; 
  }

  getSliderById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'slider/'+id+'/get-slider',{headers : tokenHeader}) ; 
  }

  update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'slider/'+id+'/update',request,{headers : tokenHeader}) ;
  }

  getHomePageMetaDataById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'home-page-meta-data/'+id+'/get-meta',{headers : tokenHeader}) ; 
  }

  updateHomePageMetaData(request : any, id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'home-page-meta-data/'+id+'/update',request,{headers : tokenHeader}) ; 
  }

}
