import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotificationEmail } from 'src/app/models/notification-email.model';
import { NotificationEmailsService } from 'src/app/services/notification-emails/notification-emails.service';

@Component({
  selector: 'app-notification-emails-edit',
  templateUrl: './notification-emails-edit.component.html',
  styleUrls: ['./notification-emails-edit.component.scss']
})
export class NotificationEmailsEditComponent implements OnInit {

  emailId : string = '' ; 
  email : NotificationEmail = null ; 
  formIsSubmitted : boolean = false ;
  _editForm : FormGroup ; 
  validationErrors : string = null ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _emailsSerivice : NotificationEmailsService,
    private _router : Router , 
    private _toastr : ToastrService,
    private _formBuilder : FormBuilder 
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.emailId = params['id'] ; 
        this.subscribeOnGetEmailObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetEmailObservable() {
    this._emailsSerivice.getEmailById(this.emailId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','notification-emails','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.email = result.email ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }


  initEditForm() {
    let group = {
      email : [this.email.email,[Validators.required,Validators.email]]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onEmailUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      email : this.getControlValue('email')  , 
    } ; 
    this.formIsSubmitted = true ; 
    this.validationErrors = null ;
    this.subscribeOnEmailUpdateObservable(request,this.emailId) ;  
  }

  subscribeOnEmailUpdateObservable(request : any , id : string) {
    this._emailsSerivice.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 400) {
          this.validationErrors = result.message ; 
          this.formIsSubmitted = false ; 
        } else if(result.success_code == 200 ) {
          this._router.navigate(['/home','notification-emails','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }


}
