import { Component, OnInit } from '@angular/core';
import { SocialMedia } from 'src/app/models/home-page/social-media.model';
import { CommonService } from 'src/app/services/common/common.service';
import { SocialMediaService } from 'src/app/services/home-page/social-media/social-media.service';

@Component({
  selector: 'app-social-media-index',
  templateUrl: './social-media-index.component.html',
  styleUrls: ['./social-media-index.component.scss']
})
export class SocialMediaIndexComponent implements OnInit {

  medias : SocialMedia[] = null ; 
  searchWord : string = '' ; 

  constructor(
    private _mediasService : SocialMediaService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnGetMediasObservable() ; 
  }

  subscribeOnGetMediasObservable() {
    this._mediasService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.medias = result.medias ; 
        }
      } , 
      (error)=> {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this._commonService.filterArrayByTitleName(this.searchWord,this.medias,'title') ; 
  }

}
