import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends BaseService {

  constructor(
    private _http : HttpClient
  ) { 
    super() ; 
  }

  filterArray(word : string , array : any[]) {
    let afterSearchArray : any [] = [] ; 
    for(let i =0 ; i<array.length ; i++) {
      if(array[i].label.toLowerCase().includes(word.toLocaleLowerCase())) {
        afterSearchArray.push(array[i]) ; 
      }
    }
    return afterSearchArray ; 
  }

  filterContentsArray(word : string , array : any[]) {
    let afterSearchArray : any [] = [] ; 
    for(let i =0 ; i<array.length ; i++) {
      if(array[i].title.toLowerCase().includes(word.toLocaleLowerCase())) {
        afterSearchArray.push(array[i]) ; 
      }
    }
    return afterSearchArray ; 
  }

  filterArrayByTitleName(word : string ,array : any[] , label :string) {
    let afterSearchArray : any [] = [] ; 
    for(let i =0 ; i<array.length ; i++) {
      if(array[i][label].toLowerCase().includes(word.toLowerCase())) {
        afterSearchArray.push(array[i]) ; 
      }
    }
    return afterSearchArray ;
  }

  storeImage(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'images/store',request,{headers : tokenHeader}) ; 
  }

  getSlug(text : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'generate-slug/'+text,{headers : tokenHeader}) ; 
  }

  
}
