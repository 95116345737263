import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    { path: '/home/dashboard', title: 'Dashboard', icon: 'icon-graph', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '', title: 'Page', icon: 'icon-doc', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/pages/index', title: 'Pages', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/pages-categories/index', title: 'Catégories', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    { path: '/home/custom-types/index', title: 'Type spécifique', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
/*     {
        path: '', title: 'Type spécifique', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/custom-types/index', title: 'Types spécifiques', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/custom-types-categories/index', title: 'Catégories', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    }, */
    {
        path: '', title: 'Menus', icon: 'ft-list', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/home-page/top-menu-elements/index', title: 'Top menu', icon: 'ft-chevrons-up', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/menus/index', title: 'Menu principal', icon: 'ft-chevrons-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Page d\'accueil', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/home-page/slider/index', title: 'Slider', icon: 'ft-image', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/home-page/trainings/index', title: 'Formations', icon: 'icon-book-open', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/home-page/training-types/index', title: 'Type de formation', icon: 'ft-align-left', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/home-page/pedagogies/index', title: 'Pédagogie', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/home-page/international/index', title: 'Bloc international', icon: 'icon-globe-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/home-page/meta/1/edit', title: 'Méta-données', icon: 'icon-badge', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            //{ path: '/home/home-page/footer-blocs/index', title: 'Footer', icon: 'ft-chevrons-down', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    { path: '/home/home-page/footer-blocs/index', title: 'Footer', icon: 'ft-chevrons-down', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/home/home-page/social-media/index', title: 'Réseaux sociaux', icon: 'ft-facebook', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/home/home-page/news-letters/index', title: 'News letters', icon: 'ft-mail', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/home/faqs/index', title: 'Faq', icon: 'icon-question', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '', title: 'Formations RH', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/training-contents/1/create', title: 'Ajouter', icon: 'fa fa-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/training-presentation/1/edit', title: 'Présentation', icon: 'ft-image', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/training-contents/1/index', title: 'Liste', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/trainings-contents-types/1/index', title: 'Types', icon: 'icon-tag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Formations DIGITAL', icon: 'ft-smartphone', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/training-contents/2/create', title: 'Ajouter', icon: 'fa fa-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/training-presentation/2/edit', title: 'Présentation', icon: 'ft-image', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/training-contents/2/index', title: 'Liste', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/trainings-contents-types/2/index', title: 'Types', icon: 'icon-tag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Formations BTS', icon: 'icon-graduation', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/training-contents/3/create', title: 'Ajouter', icon: 'fa fa-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/training-presentation/3/edit', title: 'Présentation', icon: 'ft-image', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/training-contents/3/index', title: 'Liste', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/trainings-contents-types/3/index', title: 'Types', icon: 'icon-tag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    { path: '/home/headings/index', title: 'Rubriques', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/home/notification-emails/index', title: 'Notifications', icon: 'ft-bell', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '', title: 'Emails', icon: 'ft-mail', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/home/mails-responses/1/edit', title: 'Pré-inscriptions', icon: 'fa fa-list-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/mails-responses/2/edit', title: 'D. de rappel', icon: 'fa fa-list-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/home/smtp/1/edit', title: 'SMTP', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            
        ]
    },
    { path: '/home/registrations/index', title: 'Pré-inscriptions', icon: 'ft-arrow-down', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/home/callback-requests/index', title: 'D de rappel', icon: 'ft-phone', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/home/url-redirections/index', title: 'Redirection 301', icon: 'ft-refresh-cw', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    

];
