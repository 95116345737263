import { Component, OnInit } from '@angular/core';
import { Pedagogy } from 'src/app/models/home-page/pedagogy.model';
import { PedagogiesService } from 'src/app/services/home-page/pedagogies/pedagogies.service';

@Component({
  selector: 'app-pedagogies-index',
  templateUrl: './pedagogies-index.component.html',
  styleUrls: ['./pedagogies-index.component.scss']
})
export class PedagogiesIndexComponent implements OnInit {

  pedagogies : Pedagogy[] = null ; 

  constructor(
    private _pedagogiesService : PedagogiesService , 
  ) { }

  ngOnInit() {
    this.subscribeOngetPedagogiesObservable() ;
  }

  subscribeOngetPedagogiesObservable() {
    this._pedagogiesService.getList().subscribe(
      (result) => {
        this.pedagogies = result.pedagogies ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
