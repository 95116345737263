import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FaqsService } from 'src/app/services/faqs/faqs.service';

@Component({
  selector: 'app-faq-create',
  templateUrl: './faq-create.component.html',
  styleUrls: ['./faq-create.component.scss']
})
export class FaqCreateComponent implements OnInit {

  _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _faqsService : FaqsService,
    private _formBuilder : FormBuilder,
    private _toastr : ToastrService,
    private _router : Router
  ) { }

  ngOnInit() {
    this.initCreateForm() ; 
  }

  initCreateForm() {
    let group = {
      question : ['',[Validators.required]] , 
      response : ['',[Validators.required]]
    } ; 
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this._createForm.get(controlName).value ; 
  }

  onFaqCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ;
    }
    let request = {
      question : this.getControlValue('question') , 
      response : this.getControlValue('response')
    } ;
    this.formIsSubmitted = true ; 
    this.subscribeOnFaqStoreObservable(request) ; 
  }

  subscribeOnFaqStoreObservable(request : any) {
    this._faqsService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','faqs','index']) ;
          this._toastr.success(result.message) ;
          this.formIsSubmitted = false ;   
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }


}
