import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common/common.service';
import { HeadingsService } from 'src/app/services/headings/headings.service';

@Component({
  selector: 'app-headings-create',
  templateUrl: './headings-create.component.html',
  styleUrls: ['./headings-create.component.scss']
})
export class HeadingsCreateComponent implements OnInit {

  _createForm : FormGroup  ;  
  formIsSubmitted : boolean = false ;
  fileUploaded : boolean = false ; 
  validationErrors : string = null ;  
  generatedSlug : string = '' ; 

  constructor(
    private _formBuilder : FormBuilder,
    private _router : Router,
    private _toastr : ToastrService , 
    private _headingsService :HeadingsService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.initCreateForm() ; 
  }

  initCreateForm() {
    let group = {
      title : ['',[Validators.required]] , 
      //display_title : ['',[Validators.required]] , 
      description : ['',[Validators.required]] , 
      image : ['',[Validators.required]] ,
      alt_image : [''] ,  
      meta_title : [''] , 
      meta_description : [''] , 
      keywords : ['']
    }
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._createForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ; 
      this.fileUploaded = true ;   
    }
  }

  onHeadingCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 
    let title = this.getControlValue('title') ; 
    //let display_title = this.getControlValue('display_title') ; 
    let description = this.getControlValue('description') ; 
    let image = this.getControlValue('image') ; 
    let meta_title = this.getControlValue('meta_title') ; 
    let meta_description = this.getControlValue('meta_description') ; 
    let keywords = this.getControlValue('keywords') ; 
    let alt_image = this.getControlValue('alt_image') ; 

    formData.append('title',title) ; 
    //formData.append('display_title',display_title) ; 
    formData.append('description',description) ; 
    formData.append('image',image) ; 
    formData.append('meta_title',meta_title) ; 
    formData.append('meta_description',meta_description) ; 
    formData.append('keywords',keywords) ; 
    formData.append('alt_image',alt_image) ; 
    
    this.validationErrors = null ; 
    this.formIsSubmitted = true ;
    this.subscribeOnHeadingStoreObservable(formData) ;  
  }

  subscribeOnHeadingStoreObservable(request : any) {
    this._headingsService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','headings','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        } else if(result.success_code == 400) {
          this.validationErrors = result.message ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  domain() {
    return this._headingsService.siteDomain ; 
  }

  onUrlTitleChange(event) {
    if(event.target.value.trim().length == 0) {
      this.generatedSlug = '' ; 
      return 0 ; 
    } 
    this.getGeneratedSlug(event.target.value) ; 
  }
  getGeneratedSlug(text : string) {
    this._commonService.getSlug(text).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.generatedSlug = result.slug ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
