import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Training } from 'src/app/models/training.model';
import { TrainingContentsTypesService } from 'src/app/services/trainings/training-contents-types/training-contents-types.service';
import { TrainingsService } from 'src/app/services/trainings/trainings/trainings.service';

@Component({
  selector: 'app-trainings-contents-types-create',
  templateUrl: './trainings-contents-types-create.component.html',
  styleUrls: ['./trainings-contents-types-create.component.scss']
})
export class TrainingsContentsTypesCreateComponent implements OnInit {

  _createForm : FormGroup  ; 
  trainingId : string ; 
  training : Training ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _formBuilder : FormBuilder , 
    private _typesService : TrainingContentsTypesService , 
    private _trainingsService : TrainingsService , 
    private _activatedRoute : ActivatedRoute,
    private _toastr : ToastrService , 
    private _router : Router
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.trainingId = params['trainingId'] ;
        this.subscribeOnGetTrainingObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ;
  }

  subscribeOnGetTrainingObservable() {
    this._trainingsService.getTrainingById(this.trainingId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.training = result.education ; 
          this.initCreateForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initCreateForm() {
    let group = {
      label : ['',[Validators.required]] , 
      education_id : [this.training.id,[Validators.required]]
    } ; 
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onTypeCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      label : this.getControlValue('label') , 
      education_id : this.getControlValue('education_id')
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnStoreTypeObservable(request) ; 
  }

  subscribeOnStoreTypeObservable(request : any) {
    this._typesService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','trainings-contents-types',this.training.id,'index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
