import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MailResponse } from 'src/app/models/mail-response.model';
import { CommonService } from 'src/app/services/common/common.service';
import { MailsResponsesService } from 'src/app/services/mails-responses/mails-responses.service';

@Component({
  selector: 'app-mail-responses-edit',
  templateUrl: './mail-responses-edit.component.html',
  styleUrls: ['./mail-responses-edit.component.scss']
})
export class MailResponsesEditComponent implements OnInit {

  responseId : string = '' ; 
  response : MailResponse = null ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _responsesService : MailsResponsesService , 
    private _toastr : ToastrService,
    private _router : Router , 
    private _formBuilder :  FormBuilder ,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.responseId = params['id'] ;
        this.subscribeOnGetResponseObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetResponseObservable() {
    this._responsesService.getResponseById(this.responseId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.response = result.response ; 
          this.initEditForm() ; 
        } else if(result.success_code == 404) {
          this._router.navigate(['/home','dashboard']) ;
          this._toastr.error(result.message);  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      response : [this.response.response , [Validators.required]] , 
      subject : [this.response.subject,[Validators.required]] , 
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onResponseUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      response : this.getControlValue('response') ,
      subject : this.getControlValue('subject') ,  
    } ;
    this.formIsSubmitted = true ; 
    this.subscribeOnResponseUpdateObservable(request,this.responseId) ;  

  }

  subscribeOnResponseUpdateObservable(request : any , id : string) {
    this._responsesService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
          this.response = result.response ; 
          this.initEditForm() ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onFileChange(event,fileBrowser) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl) {
    let editorValue = this.getControlValue('response') ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ; 
    let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px !important; width:100% !important" />' ; 
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl('response').setValue(finalEditorValue) ; 
  }



}
