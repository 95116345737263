import { Component, OnInit } from '@angular/core';
import { CtContentsCategoriesService } from 'src/app/services/ct-contents-categories/ct-contents-categories.service';
import { CtContentsCategory } from 'src/app/models/ct-contents-category.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomType } from 'src/app/models/custom-type.model';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common/common.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-ct-contents-categories-index',
  templateUrl: './ct-contents-categories-index.component.html',
  styleUrls: ['./ct-contents-categories-index.component.scss']
})
export class CtContentsCategoriesIndexComponent implements OnInit {

  customTypeId : string  = '' ; 
  categories : CtContentsCategory [] = null ; 
  customType : CustomType = null ; 
  searchWord : string = '' ; 

  constructor(
    private _ctContentsCategoriesService : CtContentsCategoriesService,
    private _activatedRoute : ActivatedRoute , 
    private _toastr : ToastrService , 
    private _router : Router , 
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.customTypeId = params['id'] ;
        this.subscribeOnGetCategoriesObservable();  
      } , 
      (error) => {
        console.log(error) ; 
      }
    );
  }

  subscribeOnGetCategoriesObservable() {
    this._ctContentsCategoriesService.getList(this.customTypeId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','custom-types','index']) ;
          this._toastr.error(result.message) ;  
        } else if(result.success_code == 200) {
          this.customType = result.customType ; 
          this.categories = result.categories ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredCategoriesArray() {
    return this._commonService.filterArray(this.searchWord,this.categories) ; 
  }

  onCategoryDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cette catégorie ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let cat = this.categories.find(
          (category) => {
            return category.id == +id ; 
          }
        ) ; 
        this.categories.splice(this.categories.indexOf(cat),1) ;
        this.subscribeOnDeleteCategoryObservable(id) ; 
      }
    });
  }

  subscribeOnDeleteCategoryObservable(id : string) {
    this._ctContentsCategoriesService.delete(id).subscribe(
      (result)=> {
        if(result.success_code == 200) {
          this._toastr.error(result.message) ;
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    )
  }

}
