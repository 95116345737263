import { Component,OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  isLogged : boolean = false ;

  constructor(private _authService : AuthService) {

  }

  ngOnInit() { 
    
    this.subscribeOnAuthStatusSubject() ; 
    this.setAuthStatusInService() ; 
    
  }

  subscribeOnAuthStatusSubject() {
    this._authService.authStatusSubject.subscribe(
      (authStatus : boolean) => {
        this.isLogged = authStatus ; 
      } , 
      (error) => {
        console.log(error) ;
      }
    ) ; 
  }

  setAuthStatusInService() {
    if(localStorage.getItem('token') != null && 
       localStorage.getItem('token') != "" && 
       localStorage.getItem('owner') != null && 
       localStorage.getItem('owner') != ""){
        this._authService.authStatus = true ; 
       } else {
        this._authService.authStatus = false ; 
       }
  }
}
