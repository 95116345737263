import { Component, OnInit } from '@angular/core';
import { TrainingType } from 'src/app/models/home-page/training-type.model';
import { TrainingTypesService } from 'src/app/services/home-page/training-types/training-types.service';

@Component({
  selector: 'app-training-types-index',
  templateUrl: './training-types-index.component.html',
  styleUrls: ['./training-types-index.component.scss']
})
export class TrainingTypesIndexComponent implements OnInit {

  trainingTypes : TrainingType[] = null ; 

  constructor(
    private _trainingTypesService : TrainingTypesService , 
  ) { }

  ngOnInit() {
    this.subscribeOnGetTrainingTypesObservable() ; 
  }

  subscribeOnGetTrainingTypesObservable() {
    this._trainingTypesService.getList().subscribe(
      (result) => {
        this.trainingTypes = result.types ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
