import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class MenusService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
   }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'menus/get-list',{headers : tokenHeader}) ; 
  }

  getTree() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'menus/get-structered-list',{headers : tokenHeader}) ; 
  }

  getElementById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'menus/'+id+'/get-menu',{headers : tokenHeader}) ; 
  }

  store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'menus/store',request,{headers : tokenHeader}) ; 
  }

  update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'menus/'+id+'/update',request,{headers : tokenHeader}) ; 
  }

  delete(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'menus/'+id+'/delete',{headers : tokenHeader}) ; 
  }

  updateParentsRanks(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'menus/update-parents-ranks',request,{headers : tokenHeader}) ; 
  }

  updateChildsRanks(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'menus/update-childs-ranks',request,{headers : tokenHeader}) ; 
  }
}
