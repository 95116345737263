import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr' ; 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  _loginForm : FormGroup  ; 
  formIsSubmitted : boolean = false ; 
  serverErrors : string = null ; 

  constructor(
    private _authService : AuthService,
    private _formBuilder : FormBuilder,
    private _router : Router,
    private _toastr : ToastrService
  ) { 
    
  }

  ngOnInit() {
    this.initLoginForm() ; 
  }

  initLoginForm() {
    let group = {
      email : ['',[Validators.required,Validators.email]] , 
      password : ['',[Validators.required ,Validators.minLength(8)]]
    } ; 
    this._loginForm = this._formBuilder.group(group) ; 
  }

  onLogin() {
    
    if(this._loginForm.invalid) {
      this._toastr.error('Formulaire invalide !') ;  
      return 0 ; 
    }
    this.serverErrors = null ; 
    let request = {
      email : this.getControlValue('email') ,
      password : this.getControlValue('password') 
    } ; 
    this.formIsSubmitted = true ; 
    //console.log(this.formIsUbmitted);
    this.subscribeOnLoginObservable(request) ; 
  }

  getControlValue(controlName : string) {
    return this._loginForm.get(controlName).value ; 
  }

  getControl(controlName : string) {
    return this._loginForm.get(controlName) ; 
  }
  
  subscribeOnLoginObservable(request:any) {
    this._authService.login(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          let owner = result.owner ; 
          this._authService.owner = owner ;
          localStorage.setItem('token',owner.token) ;
          localStorage.setItem('token_expiration_date',owner.token_expiration_date) ; 
          delete owner.id ; 
          delete owner.api_expiration_date ; 
          localStorage.setItem('owner',JSON.stringify(owner)) ;   
          this._router.navigate(['/home','dashboard']) ; 
          this._authService.authStatus = true ;  
          this._toastr.success('Bienvenu à nouveau '+owner.first_name) ; 
        } else if(result.success_code == 401) {
          this.serverErrors = result.message ; 
          this.getControl('password').setValue('') ;
        }
        this.formIsSubmitted = false ;
        //console.log(this.formIsUbmitted);
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
