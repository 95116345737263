import { Component, OnInit } from '@angular/core';
import { SliderContent } from 'src/app/models/home-page/slider-content.model';
import { SliderService } from 'src/app/services/home-page/slider/slider.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormStyle } from '@angular/common';

@Component({
  selector: 'app-slider-edit',
  templateUrl: './slider-edit.component.html',
  styleUrls: ['./slider-edit.component.scss']
})
export class SliderEditComponent implements OnInit {

  sliderContent : SliderContent = null ; 
  contentId : string="" ; 
  private _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _sliderService : SliderService , 
    private _router : Router , 
    private _toastr : ToastrService,
    private _activatedRoute : ActivatedRoute,
    private _formBuilder : FormBuilder
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ;
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.contentId = params['id'] ; 
        this.subscribeOnGetContentObservable();
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetContentObservable() {
    this._sliderService.getSliderById(this.contentId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','slider','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.sliderContent = result.content ;
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  } 

  initEditForm() {
    let group = {
      first_title : [this.sliderContent.first_title , [Validators.required]] , 
      second_title : [this.sliderContent.second_title] , 
      url : [this.sliderContent.url] , 
      text : [this.sliderContent.text] , 
      image : [''],
      alt_image : [this.sliderContent.alt_image] , 
      status : [this.sliderContent.status,[Validators.required]]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
    //console.log(this._editForm.value) ;
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._editForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ;   
    }
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this._editForm.get(controlName).value ; 
  }

  onStatusSwitch() {
    if(this.getControlValue('status') == 1) {
      this._editForm.patchValue({
        status : 0 ,
      }) ;
    } else if(this.getControlValue('status') == 0) {
      this._editForm.patchValue({
        status : 1 ,
      }) ;
    }
    //console.log(this.getControlValue('status')) ; 
  }

  onSliderContentUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ;
    let first_title = this.getControlValue('first_title') ;  
    let second_title = this.getControlValue('second_title') ; 
    let text = this.getControlValue('text') ; 
    let url = this.getControlValue('url') ; 
    let status = this.getControlValue('status') ; 
    let image = this.getControlValue('image') ; 
    let alt_image  = this.getControlValue('alt_image') ; 

    formData.append('first_title',first_title) ; 
    formData.append('second_title',second_title) ; 
    formData.append('text',text) ; 
    formData.append('url',url) ; 
    formData.append('status',status) ; 
    formData.append('image',image) ;
    formData.append('alt_image',alt_image) ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnContentUpdateObservable(formData,this.contentId) ;
  }

  subscribeOnContentUpdateObservable(request : any , id : string) {
    this._sliderService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','slider','index']) ;
          this.formIsSubmitted = false; 
          this._toastr.success(result.message) ;  
        }
      }, 
      (error) => {
        console.log(error) ; 
      }
    )
  }

}
