import { Component, OnInit } from '@angular/core';
import { NewsLetter } from 'src/app/models/home-page/news-letter.model';
import { CommonService } from 'src/app/services/common/common.service';
import { NewsLettersService } from 'src/app/services/home-page/news-letters/news-letters.service';

@Component({
  selector: 'app-news-letters-index',
  templateUrl: './news-letters-index.component.html',
  styleUrls: ['./news-letters-index.component.scss']
})
export class NewsLettersIndexComponent implements OnInit {

  newsletters : NewsLetter[] = null ; 
  searchWord : string = '' ;
  p : number = 1 ; 

  constructor(
    private _newsLettersService : NewsLettersService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnGetNewsLettersObservable() ; 
  }

  subscribeOnGetNewsLettersObservable() {
    this._newsLettersService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.newsletters = result.newsletters ; 
        }
      } ,
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this._commonService.filterArrayByTitleName(this.searchWord,this.newsletters,'email'); 
  }

  onExport() {
    window.open(this._newsLettersService.base+'news-letters/export','popup'/* ,'width=0,height=0' */) ; 
  }

}
