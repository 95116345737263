import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UrlRedirection } from 'src/app/models/url-redirection.model';
import { CommonService } from 'src/app/services/common/common.service';
import { UrlsRedirectionsService } from 'src/app/services/urls-redirections/urls-redirections.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-url-redirections-index',
  templateUrl: './url-redirections-index.component.html',
  styleUrls: ['./url-redirections-index.component.scss']
})
export class UrlRedirectionsIndexComponent implements OnInit {

  searchWord : string = '' ; 
  urls : UrlRedirection[] = null ; 
  p : number = 1 ; 
  
  constructor(
    private _redirectionsService : UrlsRedirectionsService,
    private _commonService : CommonService,
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetUrlsObservable() ; 
  }

  subscribeOnGetUrlsObservable() {
    this._redirectionsService.getList().subscribe(
      (result) => {
        //console.log(result) ; 
        if(result.success_code == 200)  {
          this.urls = result.urls ; 
         
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this.searchWord == '' ? this.urls : this._commonService.filterArrayByTitleName(this.searchWord,this.urls,'old_url') ; 
  }

  onUrlDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cette redirection ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let url = this.urls.find(
          (u) => {
            return u.id == +id ; 
          }
        ) ; 
        this.urls.splice(this.urls.indexOf(url),1) ;
        this.subscribeOnRedirectionDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnRedirectionDeleteObservable(id : string) {
    this._redirectionsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ;
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
