import { Component, OnInit } from '@angular/core';
import { CustomType } from 'src/app/models/custom-type.model';
import { FormGroup, Validators, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomTypesService } from 'src/app/services/custom-types/custom-types.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomTypeCategory } from 'src/app/models/custom-type-category.model';

@Component({
  selector: 'app-custom-type-edit',
  templateUrl: './custom-type-edit.component.html',
  styleUrls: ['./custom-type-edit.component.scss']
})
export class CustomTypeEditComponent implements OnInit {

  customType : any = null ; 
  private _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ;
  customTypeId : string = '' ; 
  validationErrors : string = null ; 
  //categories : CustomTypeCategory[] = null ;  
  //checkedCategories : number[] = null;

  constructor(
    private _toastr : ToastrService , 
    private _customTypeService : CustomTypesService , 
    private _router : Router ,
    private _activatedRoute : ActivatedRoute,
    private _formBuilder : FormBuilder
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.customTypeId = params['id'] ; 
        this.subscribeOnGetCustomTypeObservable() ;
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetCustomTypeObservable() {
    this._customTypeService.getCustomTypeById(this.customTypeId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','custom-types','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.customType = result.custom_type ;
          //this.categories = result.all_categories ;
          //this.checkedCategories = result.custom_type_categories; 
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ;
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      label : [this.customType.label,[Validators.required]] , 
      //categories : new FormArray([],Validators.required), 
    } ;
    this._editForm = this._formBuilder.group(group) ; 
    //const formArray = this.getControl('categories') as FormArray ;
    /*for(let i= 0 ; i< this.checkedCategories.length ; i++) {
      formArray.push(new FormControl(this.checkedCategories[i])) ; 
    }*/
      
  }

  /*onCheck(event) {
    const formArray = this.getControl('categories') as FormArray ; 
    if(event.target.checked) {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value == event.target.value) {
            formArray.removeAt(index) ;
            formArray.push(new FormControl(event.target.value)) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
      if(index == formArray.controls.length) {
        formArray.push(new FormControl(event.target.value)) ; 
      }
      
    } else {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value == event.target.value) {
            formArray.removeAt(index) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
    }
    //console.log(this.getControl('categories').value) ; 
  }*/

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this._editForm.get(controlName).value ; 
  }

  onCustomTypeUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }

    this.validationErrors = null ; 
    let request = {
      label : this.getControlValue('label') , 
      //categories : this.getControlValue('categories')  
    } ;

    this.formIsSubmitted = true ; 
    this.subscribeOnCustomTypeUpdateObservable(request,this.customTypeId) ; 
    
  }

  subscribeOnCustomTypeUpdateObservable(request : any , id : string) {
    this._customTypeService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','custom-types','index']) ;
          this.formIsSubmitted = false; 
          this._toastr.success(result.message) ;
          setTimeout(
            () => {
              window.location.href="/home/custom-types/index" ;
            } , 500
          ) ;   
        } else if(result.success_code == 400) {
          this.validationErrors = result.message ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }



}
