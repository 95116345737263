import { Component, OnInit} from '@angular/core';
import { Registration } from 'src/app/models/registration.model';
import { CommonService } from 'src/app/services/common/common.service';
import { RegistrationsService } from 'src/app/services/registrations/registrations.service';


@Component({
  selector: 'app-preregistrations-index',
  templateUrl: './preregistrations-index.component.html',
  styleUrls: ['./preregistrations-index.component.scss'],
  
})
export class PreregistrationsIndexComponent implements OnInit {
   
  registrations : Registration[] = null ; 
  searchWord : string = '' ; 
  p : number =1 ; 

  constructor(
    private _registrationsService : RegistrationsService , 
    private _commonService : CommonService    
  ) { 
     
  }

  ngOnInit() {
    this.subscribeOnGetRegistrationsObservable() ; 
  }

  subscribeOnGetRegistrationsObservable() {
    this._registrationsService.getList().subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.registrations = result.registrations ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this.searchWord == '' ? this.registrations : this._commonService.filterArrayByTitleName(this.searchWord,this.registrations,'email')
  }

  onExport() {
    window.open(this._registrationsService.base+'registrations/export','popup'/* ,'width=0,height=0' */) ; 
  }


}
