import { Component, OnDestroy, OnInit  } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Menu } from 'src/app/models/menu.model';
import { MenusService } from 'src/app/services/menus/menus.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-menus-index',
  templateUrl: './menus-index.component.html',
  styleUrls: ['./menus-index.component.scss'],
  
})
export class MenusIndexComponent implements OnInit,OnDestroy {
 
  tree : any[] = null ; 
  isCollapsed = true ; 
  dragulaSubscription = new Subscription() ; 

  
  constructor(
    private _elementsService : MenusService,
    private dragulaService : DragulaService,
    private  _toastr : ToastrService
  ) {
    
  }
  ngOnDestroy(): void {
    this.dragulaService.destroy('SPILL') ; 
    this.dragulaSubscription.unsubscribe() ; 
  }

  ngOnInit() {
    this.subscribeOnGetTreeObservable() ; 

  }

  subscribeOnGetTreeObservable() {
    this._elementsService.getTree().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.tree = result.tree ;
          this.initDragulaDragAndDrop() ; 
          this.subscribeOnDragulaEvent() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initDragulaDragAndDrop() {
    this.dragulaService.createGroup("SPILL", {
      removeOnSpill: false
    });
  }

  subscribeOnDragulaEvent() {
    this.dragulaSubscription =  this.dragulaService.drop('SPILL').subscribe(
      ({name,el,source}) => { 
        let selectedClasses = document.querySelectorAll('.to-sort') ;
        let elementsIds = [] ;    
        selectedClasses.forEach(function(element,key){
          elementsIds.push(element.id) ; 
        }) ;
        let lastIndex = elementsIds.length - 1  ; 
        elementsIds.splice(lastIndex,1) ; 
        let request = {
          elements_ids : elementsIds
        } ; 
        this.subscribeOnUpdateParentsRanksObservable(request) ; 
      }
    ) ;
  }

  subscribeOnUpdateParentsRanksObservable(request : any) {
    this._elementsService.updateParentsRanks(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cet élément ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        /* let customType = this.customTypes.find(
          (type) => {
            return type.id == +id ; 
          }
        ) ; */ 
        //this.customTypes.splice(this.customTypes.indexOf(customType),1) ;
        this.tree = null ; 
        this.subscribeOnElementDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnElementDeleteObservable(id : string) {
    this._elementsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
          /* setTimeout(
            () => {
              window.location.href = "/home/menus/index" ; 
            } , 500
          ) ; */
          this.tree = result.tree ; 
          this.removeFromArray(id,this.tree) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  removeFromArray(id : string,array) {
    for(let i = 0 ; i<array.length ; i++)  {
      if(array[i].id == id) {
        array.splice(array.indexOf(array[i]),1) ; 
      } else {
        if(array[i].sub_menus) {
          this.removeFromArray(id,array[i].sub_menus) ; 
        }
      }
    }
  }

  sortArrayByChildRank(array : any[]) {
    if(array != null && array.length > 0) {
      array.sort(
        (a,b)=> {
          return a.child_rank > b.child_rank ? 1 : -1 ;
        }
      )
      return array ;
    }
  }


}
