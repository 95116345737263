import {Component, QueryList, ViewChildren,OnInit} from '@angular/core';
import {CustomType} from '../../models/custom-type.model';
import {NgbdSortableHeader, SortEvent} from '../../directives/sortable.directive';
import { CustomTypesService } from 'src/app/services/custom-types/custom-types.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Component({
  selector: 'app-custom-types-index',
  templateUrl: './custom-types-index.component.html',
  styleUrls: ['./custom-types-index.component.scss']
})
export class CustomTypesIndexComponent implements OnInit {

  customTypes : CustomType[] = null;
  searchWord : string = '' ; 

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    private _customTypeService : CustomTypesService,
    private _commonService : CommonService,
    private _toastr : ToastrService
    ) { }

  ngOnInit() {
    this.subscribeOnGetCustomTypesListObservable() ; 
  }

  subscribeOnGetCustomTypesListObservable() {
    this._customTypeService.getList().subscribe(
      (result) => {
        this.customTypes = result.custom_types ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredCustomTypesArray() {
    return this._commonService.filterArray(this.searchWord,this.customTypes) ; 
  }

  onCustomTypeDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer ce type ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let customType = this.customTypes.find(
          (type) => {
            return type.id == +id ; 
          }
        ) ; 
        this.customTypes.splice(this.customTypes.indexOf(customType),1) ;
        this.subscribeOnDeleteCustomTypeObservable(id) ; 
      }
    });
  }

  subscribeOnDeleteCustomTypeObservable(id : string) {
    this._customTypeService.delete(id).subscribe(
      (result) => {
        this._toastr.success(result.message) ;
        setTimeout(
          () => {
            window.location.href="/home/custom-types/index" ;
          } , 500
        ) ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
