import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { AgmCoreModule } from "@agm/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "./shared.module";
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from './home/home.component';
import { PagesIndexComponent } from './pages/pages-index/pages-index.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PagesCreateComponent } from './pages/pages-create/pages-create.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { PagesEditComponent } from './pages/pages-edit/pages-edit.component';
import { PagesCategoriesIndexComponent } from './pages-categories/pages-categories-index/pages-categories-index.component';
import { PagesCategoriesCreateComponent } from './pages-categories/pages-categories-create/pages-categories-create.component';
import { PagesCategoriesEditComponent } from './pages-categories/pages-categories-edit/pages-categories-edit.component';
import { CustomTypesIndexComponent } from './custom-types/custom-types-index/custom-types-index.component';
import { CustomTypeCreateComponent } from './custom-types/custom-type-create/custom-type-create.component';
import { CustomTypeEditComponent } from './custom-types/custom-type-edit/custom-type-edit.component';
import { CustomTypesCategoriesIndexComponent } from './custom-types-categories/custom-types-categories-index/custom-types-categories-index.component';
import { CustomTypesCategoriesCreateComponent } from './custom-types-categories/custom-types-categories-create/custom-types-categories-create.component';
import { CustomTypesCategoriesEditComponent } from './custom-types-categories/custom-types-categories-edit/custom-types-categories-edit.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ToastrModule } from "ngx-toastr";
import { UiSwitchModule } from 'ngx-ui-switch';
import { SliderIndexComponent } from './home-page/slider/slider-index/slider-index.component';
import { SliderEditComponent } from './home-page/slider/slider-edit/slider-edit.component';
import { TrainingsIndexComponent } from './home-page/trainings/trainings-index/trainings-index.component';
import { TrainingEditComponent } from './home-page/trainings/training-edit/training-edit.component';
import { CustomTypeContentIndexComponent } from './custom-type-content/custom-type-content-index/custom-type-content-index.component';
import { CustomTypeContentCreateComponent } from './custom-type-content/custom-type-content-create/custom-type-content-create.component';
import { TrainingTypesIndexComponent } from './home-page/training-types/training-types-index/training-types-index.component';
import { TrainingTypesEditComponent } from './home-page/training-types/training-types-edit/training-types-edit.component';
import { PedagogiesIndexComponent } from './home-page/pedagogy/pedagogies-index/pedagogies-index.component';
import { PedagogiesEditComponent } from './home-page/pedagogy/pedagogies-edit/pedagogies-edit.component';
import { InternationalIndexComponent } from './home-page/international/international-index/international-index.component';
import { InternationalEditComponent } from './home-page/international/international-edit/international-edit.component';
import { CtContentsCategoriesIndexComponent } from './ct-contents-categories/ct-contents-categories-index/ct-contents-categories-index.component';
import { CtContentsCategoriesCreateComponent } from './ct-contents-categories/ct-contents-categories-create/ct-contents-categories-create.component';
import { CtContentsCategoriesEditComponent } from './ct-contents-categories/ct-contents-categories-edit/ct-contents-categories-edit.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CustomTypeContentEditComponent } from './custom-type-content/custom-type-content-edit/custom-type-content-edit.component';
import { FooterBlocsIndexComponent } from './home-page/footer/footer-blocs/footer-blocs-index/footer-blocs-index.component';
import { FooterBlocsCreateComponent } from './home-page/footer/footer-blocs/footer-blocs-create/footer-blocs-create.component';
import { FooterBlocsEditComponent } from './home-page/footer/footer-blocs/footer-blocs-edit/footer-blocs-edit.component';
import { TopMenuElementsIndexComponent } from './home-page/menus/top-menu/top-menu-elements-index/top-menu-elements-index.component';
import { TopMenuElementsEditComponent } from './home-page/menus/top-menu/top-menu-elements-edit/top-menu-elements-edit.component';
import { FooterElementsIndexComponent } from './home-page/footer/footer-elements/footer-elements-index/footer-elements-index.component';
import { FooterElementsCreateComponent } from './home-page/footer/footer-elements/footer-elements-create/footer-elements-create.component';
import { FooterElementsEditComponent } from './home-page/footer/footer-elements/footer-elements-edit/footer-elements-edit.component';
import { SocialMediaIndexComponent } from './home-page/social-media/social-media-index/social-media-index.component';
import { SocialMediaEditComponent } from './home-page/social-media/social-media-edit/social-media-edit.component';
import { NewsLettersIndexComponent } from './home-page/news-letters/news-letters-index/news-letters-index.component';
import { NgbdSortableHeader } from './directives/sortable.directive';
import { MenusIndexComponent } from './menus/menus-index/menus-index.component';
import { MenusCreateComponent } from './menus/menus-create/menus-create.component';
import { MenusEditComponent } from './menus/menus-edit/menus-edit.component';
import { TreeComponent } from './menus/tree/tree.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { DragulaModule } from 'ng2-dragula';
import { FaqIndexComponent } from './faq/faq-index/faq-index.component';
import { FaqCreateComponent } from './faq/faq-create/faq-create.component';
import { FaqEditComponent } from './faq/faq-edit/faq-edit.component';
import { TrainingsContentsTypesIndexComponent } from './trainings/training-contents-types/trainings-contents-types-index/trainings-contents-types-index.component';
import { TrainingsContentsTypesCreateComponent } from './trainings/training-contents-types/trainings-contents-types-create/trainings-contents-types-create.component';
import { TrainingsContentsTypesEditComponent } from './trainings/training-contents-types/trainings-contents-types-edit/trainings-contents-types-edit.component';
import { TrainingPresentationEditComponent } from './trainings/training-presentation/training-presentation-edit/training-presentation-edit.component';
import { TrainingContentsIndexComponent } from './trainings/training-contents/training-contents-index/training-contents-index.component';
import { TrainingContentsCreateComponent } from './trainings/training-contents/training-contents-create/training-contents-create.component';
import { TrainingContentsEditComponent } from './trainings/training-contents/training-contents-edit/training-contents-edit.component';
import { HeadingsIndexComponent } from './headings/headings-index/headings-index.component';
import { HeadingsCreateComponent } from './headings/headings-create/headings-create.component';
import { HeadingsEditComponent } from './headings/headings-edit/headings-edit.component';
import { HeadingItemsIndexComponent } from './heading-items/heading-items-index/heading-items-index.component';
import { HeadingItemsCreateComponent } from './heading-items/heading-items-create/heading-items-create.component';
import { HeadingItemsEditComponent } from './heading-items/heading-items-edit/heading-items-edit.component';
import { NotificationEmailsIndexComponent } from './notification-emails/notification-emails-index/notification-emails-index.component';
import { NotificationEmailsCreateComponent } from './notification-emails/notification-emails-create/notification-emails-create.component';
import { NotificationEmailsEditComponent } from './notification-emails/notification-emails-edit/notification-emails-edit.component';
import { MailResponsesEditComponent } from './mails-responses/mail-responses-edit/mail-responses-edit.component';
import { SmtpEditComponent } from './smtp/smtp-edit/smtp-edit.component';
import { HomePageMetaEditComponent } from './home-page/home-page-meta/home-page-meta-edit/home-page-meta-edit.component';
import { PreregistrationsIndexComponent } from './pre-registrations/preregistrations-index/preregistrations-index.component';
import { CallbackRequestsIndexComponent } from './callback-requests/callback-requests-index/callback-requests-index.component';
import { ProfileComponent } from './profile/profile.component';
import { ChartistModule } from 'ng-chartist';
import { UrlRedirectionsIndexComponent } from './url-redirections/url-redirections-index/url-redirections-index.component';
import { UrlRedirectionsCreateComponent } from './url-redirections/url-redirections-create/url-redirections-create.component';
import { UrlRedirectionsEditComponent } from './url-redirections/url-redirections-edit/url-redirections-edit.component';
//import { Ng2SmartTableModule } from 'ng2-smart-table';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    HomeComponent,
    PagesIndexComponent,
    PagesCreateComponent,
    PagesEditComponent,
    PagesCategoriesIndexComponent,
    PagesCategoriesCreateComponent,
    PagesCategoriesEditComponent,
    CustomTypesIndexComponent,
    CustomTypeCreateComponent,
    CustomTypeEditComponent,
    CustomTypesCategoriesIndexComponent,
    CustomTypesCategoriesCreateComponent,
    CustomTypesCategoriesEditComponent,
    SpinnerComponent,
    SliderIndexComponent,
    SliderEditComponent,
    TrainingsIndexComponent,
    TrainingEditComponent,
    CustomTypeContentIndexComponent,
    CustomTypeContentCreateComponent,
    TrainingTypesIndexComponent,
    TrainingTypesEditComponent,
    PedagogiesIndexComponent,
    PedagogiesEditComponent,
    InternationalIndexComponent,
    InternationalEditComponent,
    CtContentsCategoriesIndexComponent,
    CtContentsCategoriesCreateComponent,
    CtContentsCategoriesEditComponent,
    CustomTypeContentEditComponent,
    FooterBlocsIndexComponent,
    FooterBlocsCreateComponent,
    FooterBlocsEditComponent,
    TopMenuElementsIndexComponent,
    TopMenuElementsEditComponent,
    FooterElementsIndexComponent,
    FooterElementsCreateComponent,
    FooterElementsEditComponent,
    SocialMediaIndexComponent,
    SocialMediaEditComponent,
    NewsLettersIndexComponent,
    NgbdSortableHeader,
    MenusIndexComponent,
    MenusCreateComponent,
    MenusEditComponent,
    TreeComponent,
    FaqIndexComponent,
    FaqCreateComponent,
    FaqEditComponent,
    TrainingsContentsTypesIndexComponent,
    TrainingsContentsTypesCreateComponent,
    TrainingsContentsTypesEditComponent,
    TrainingPresentationEditComponent,
    TrainingContentsIndexComponent,
    TrainingContentsCreateComponent,
    TrainingContentsEditComponent,
    HeadingsIndexComponent,
    HeadingsCreateComponent,
    HeadingsEditComponent,
    HeadingItemsIndexComponent,
    HeadingItemsCreateComponent,
    HeadingItemsEditComponent,
    NotificationEmailsIndexComponent,
    NotificationEmailsCreateComponent,
    NotificationEmailsEditComponent,
    MailResponsesEditComponent,
    SmtpEditComponent,
    HomePageMetaEditComponent,
    PreregistrationsIndexComponent,
    CallbackRequestsIndexComponent,
    ProfileComponent,
    UrlRedirectionsIndexComponent,
    UrlRedirectionsCreateComponent,
    UrlRedirectionsEditComponent
    //FooterComponent,
    //FooterBlocsIndexComponent,
    
    //SidebarComponent,
    //FooterComponent,
    //NavbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CKEditorModule,
    AgmCoreModule.forRoot({
      apiKey: "YOUR KEY"
    }),
    HttpClientModule,
    PerfectScrollbarModule,
    //NgbModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    UiSwitchModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ClipboardModule,
    NgxPaginationModule,
    DragulaModule.forRoot(),
    ChartistModule
    //Ng2SmartTableModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
