import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrainingTypesService extends BaseService {

  constructor(private _http : HttpClient) { 
    super() ; 
  }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'training-types/get-list',{headers : tokenHeader}) ; 
  }

  getTtrainingTypeById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'training-types/'+id+'/get-training-type',{headers : tokenHeader}) ;
  }

  update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'training-types/'+id+'/update',request,{headers : tokenHeader}) ;
  }
}
