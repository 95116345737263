import { Component, OnInit } from '@angular/core';
import { CallbackRequest } from 'src/app/models/callback-request.model';
import { CallbackRequestsService } from 'src/app/services/callback-requests/callback-requests.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-callback-requests-index',
  templateUrl: './callback-requests-index.component.html',
  styleUrls: ['./callback-requests-index.component.scss']
})
export class CallbackRequestsIndexComponent implements OnInit {

  requests : CallbackRequest[] = null ; 
  searchWord : string = '' ; 
  p : number = 1 ; 

  constructor(
    private _requestsService : CallbackRequestsService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnGetRequestsObservable() ; 
  }

  subscribeOnGetRequestsObservable() {
    this._requestsService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.requests = result.requests ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this.searchWord == '' ? this.requests : this._commonService.filterArrayByTitleName(this.searchWord,this.requests,'email') ; 
  }

  onExport() {
    window.open(this._requestsService.base+'callback-requests/export','popup'/* ,'width=0,height=0' */) ;
  }

}
