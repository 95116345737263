import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InternationalService extends BaseService {

  constructor(private _http : HttpClient) { 
    super() ; 
  }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'international/get-list',{headers : tokenHeader}) ;
  }

  getInternationalById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'international/'+id+'/get-international',{headers : tokenHeader}) ; 
  }

  update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'international/'+id+'/update',request,{headers : tokenHeader}) ;
  }
}
