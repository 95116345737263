import { Component, OnInit } from '@angular/core';
import { Pedagogy } from 'src/app/models/home-page/pedagogy.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PedagogiesService } from 'src/app/services/home-page/pedagogies/pedagogies.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pedagogies-edit',
  templateUrl: './pedagogies-edit.component.html',
  styleUrls: ['./pedagogies-edit.component.scss']
})
export class PedagogiesEditComponent implements OnInit {

  pedagogy : Pedagogy = null ;
  formIsSubmitted : boolean = false ;
  _editForm : FormGroup 
  pedagogyId : string = '' ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _router : Router , 
    private _pedagogiesService : PedagogiesService , 
    private _toastr : ToastrService,
    private _formBuilder : FormBuilder
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.pedagogyId = params['id'] ; 
        this.subscribeOnGetPedagogyObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetPedagogyObservable() {
    this._pedagogiesService.getPedagogyById(this.pedagogyId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','pedagogies','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.pedagogy = result.pedagogy ;
          
          this.initEditForm() ;  
        } 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.pedagogy.title,[Validators.required]] , 
      text : [this.pedagogy.text,[Validators.required]] , 
      image : '' , 
      alt_image : [this.pedagogy.alt_image] , 
    }
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onPedagogyUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 
    let title = this.getControlValue('title') ; 
    let text = this.getControlValue('text') ; 
    let image = this.getControlValue('image') ;
    let alt_image = this.getControlValue('alt_image') ;  
    formData.append('title',title) ; 
    formData.append('text',text) ; 
    formData.append('image',image) ; 
    formData.append('alt_image',alt_image) ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnPedagogyUpdateObservable(formData,this.pedagogyId) ; 
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._editForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ;   
    }
  }

  subscribeOnPedagogyUpdateObservable(request : any , id : string) {
    this._pedagogiesService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','pedagogies','index']) ;
          this.formIsSubmitted = false; 
          this._toastr.success(result.message) ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
