import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTypesService } from 'src/app/services/custom-types/custom-types.service';
import { CustomType } from 'src/app/models/custom-type.model';
import { ToastrService } from 'ngx-toastr';
import { CtContentsCategory } from 'src/app/models/ct-contents-category.model';
import { CtContentsCategoriesService } from 'src/app/services/ct-contents-categories/ct-contents-categories.service';
import { Validators, FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CustomTypesContentsService } from 'src/app/services/custom-types-contents/custom-types-contents.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-custom-type-content-create',
  templateUrl: './custom-type-content-create.component.html',
  styleUrls: ['./custom-type-content-create.component.scss']
})
export class CustomTypeContentCreateComponent implements OnInit {

  customTypeId : string = '' ; 
  customType : CustomType = null ; 
  categories : CtContentsCategory[] = null ;
  _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ;  
  generatedSlug : string = '' ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _customTypesService : CustomTypesService,
    private _router : Router,
    private _toastr : ToastrService,
    private _categoriesService : CtContentsCategoriesService,
    private _formBuilder : FormBuilder,
    private _contentsService : CustomTypesContentsService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.customTypeId = params['id'] ;
        this.subscribeOnGetCustomTypeObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetCustomTypeObservable() {
    this._customTypesService.getCustomTypeById(this.customTypeId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','custom-types','index']) ; 
          this._toastr.error(result.message); 
        }else if(result.success_code == 200) {
          this.customType = result.custom_type ; 
          this.subscribeOnGetCategoriesObservable(result.custom_type.id) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetCategoriesObservable(customTypeId : string) {
    this._categoriesService.getList(customTypeId).subscribe(
      (result) => {
        this.categories = result.categories ; 
        this.initCreateForm() ; 
      } , 
      (error)=> {
        console.log(error) ; 
      }
    ); 
  }

  initCreateForm() {
    let group = {
      title : ['',[Validators.required]] , 
      //display_title : ['',Validators.required] , 
      image : '' , 
      alt_image : '' , 
      content : [''] , 
      status : [1,[Validators.required]] ,
      categories : new FormArray([],Validators.required),
      custom_type_id : [this.customType.id,[Validators.required]] , 
      meta_title : '' , 
      meta_description : '' , 
      keywords : '' , 
    } ; 
    this._createForm = this._formBuilder.group(group) ;
     
  }

  getControl(controlName){
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onCheck(event) {
    const formArray = this.getControl('categories') as FormArray ; 
    //formArray.push(new FormControl(event.target.value)) ;
    if(event.target.checked) {
      formArray.push(new FormControl(event.target.value)) ;
    } else {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value === event.target.value) {
            formArray.removeAt(index) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
    }
    //console.log(this.getControl('categories').value) ; 
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._createForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ;   
    }
  }

  onStatusSwitch() {
    if(this.getControlValue('status') == 1) {
      this._createForm.patchValue({
        status : 0 ,
      }) ;
    } else if(this.getControlValue('status') == 0) {
      this._createForm.patchValue({
        status : 1 ,
      }) ;
    }
    //console.log(this.getControlValue('status')) ; 
  }

  onContentCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 
    let title = this.getControlValue('title') ; 
    //let display_title = this.getControlValue('display_title') ; 
    let image = this.getControlValue('image') ;
    let alt_image = this.getControlValue('alt_image') ;  
    let content = this.getControlValue('content') ; 
    let categories = this.getControlValue('categories') ; 
    let status = this.getControlValue('status') ; 
    let custom_type_id = this.getControlValue('custom_type_id') ; 
    let meta_title = this.getControlValue('meta_title') ; 
    let meta_description = this.getControlValue('meta_description') ; 
    let keywords = this.getControlValue('keywords') ; 
    formData.append('title',title) ; 
    formData.append('image',image) ; 
    formData.append('alt_image',alt_image) ; 
    formData.append('content',content) ; 
    formData.append('categories',categories) ; 
    formData.append('status',status) ; 
    formData.append('custom_type_id',custom_type_id) ; 
    formData.append('meta_title',meta_title) ; 
    formData.append('meta_description',meta_description) ; 
    formData.append('keywords',keywords) ; 
    //formData.append('display_title',display_title) ; 
    //console.log(formData.get('categories')) ;
    this.formIsSubmitted = true ;
    this.subscribeOnContentCreateObservable(formData) ;  
  }

  subscribeOnContentCreateObservable(request : any) {
    this._contentsService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.formIsSubmitted = false ; 
          this._router.navigate(['/home','custom-type-content',this.customType.id,'index']) ;
          this._toastr.success(result.message) ;
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onEditorFileChange(event,fileBrowser) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl) {
    let editorValue = this.getControlValue('content') ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ; 
    let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px !important; width:100% !important" />' ; 
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl('content').setValue(finalEditorValue) ; 
  }

  domain() {
    return this._contentsService.siteDomain ; 
  }

  onUrlTitleChange(event) {
    if(event.target.value.trim().length == 0) {
      this.generatedSlug = '' ; 
      return 0 ; 
    } 
    this.getGeneratedSlug(event.target.value) ; 
  }
  getGeneratedSlug(text : string) {
    this._commonService.getSlug(text).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.generatedSlug = result.slug ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
