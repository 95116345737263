import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class CallbackRequestsService extends BaseService {

  constructor(private _http : HttpClient) { 
    super() ; 
  }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'callback-requests/get-list',{headers : tokenHeader}) ; 
  }

  get base() {
    return this.baseUrl ; 
  }
}
