import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Heading } from 'src/app/models/heading.model';
import { CommonService } from 'src/app/services/common/common.service';
import { HeadingItemsService } from 'src/app/services/heading-items/heading-items.service';
import { HeadingsService } from 'src/app/services/headings/headings.service';

@Component({
  selector: 'app-heading-items-create',
  templateUrl: './heading-items-create.component.html',
  styleUrls: ['./heading-items-create.component.scss']
})
export class HeadingItemsCreateComponent implements OnInit {

  headingId : string = '' ; 
  heading : Heading = null ; 
  _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _headingsService : HeadingsService,
    private _toastr : ToastrService,
    private _router : Router , 
    private _formBuilder : FormBuilder,
    private _commonService : CommonService,
    private _itemsService : HeadingItemsService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.headingId = params['headingId'] ; 
        this.subscribeOnGetHeadingObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetHeadingObservable() {
    this._headingsService.getHeadingById(this.headingId).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.heading = result.heading ; 
          this.initCreateForm() ; 
        } else if(result.success_code == 404) {
          this._router.navigate(['/home','headings','index']) ; 
          this._toastr.error(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initCreateForm() {
    let group = {
      title : ['',[Validators.required]] , 
      content : ['',Validators.required] , 
      heading_id : [this.heading.id,[Validators.required]]
    }
    this._createForm = this._formBuilder.group(group) ; 
    //this.addImageToEditor() ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ;
  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onFileChange(event,fileBrowser) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl) {
    let editorValue = this.getControlValue('content') ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ; 
    let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px !important; width:100% !important" />' ; 
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl('content').setValue(finalEditorValue) ; 
  }

  onItemCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      title : this.getControlValue('title') , 
      content : this.getControlValue('content') ,
      heading_id: this.getControlValue('heading_id') , 
    }
    this.formIsSubmitted = true ; 
    this.subscribeOnStoreItemObservable(request) ; 
  }

  subscribeOnStoreItemObservable(request : any) {
    this._itemsService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','heading-items',this.heading.id,'index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error)=> {
        console.log(error) ; 
      }
    ) ;
  }


}
