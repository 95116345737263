import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class PagesCategoriesService extends BaseService {

  constructor(private _http : HttpClient) { 
    super() ; 
  }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'pages-categories/get-list',{headers : tokenHeader}) ; 
  }

  getCategoryById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'pages-categories/'+id+'/get-category',{headers : tokenHeader}) ; 
  }

  store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'pages-categories/store',request,{headers : tokenHeader}) ; 
  }

  update(request : any,id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'pages-categories/'+id+'/update',request,{headers : tokenHeader}) ; 
  }

  delete(id:string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'pages-categories/'+id+'/delete',{headers : tokenHeader}) ; 
  }

}
