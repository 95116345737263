import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SmtpConfig } from 'src/app/models/smtp-config.model';
import { SmtpService } from 'src/app/services/smtp/smtp.service';

@Component({
  selector: 'app-smtp-edit',
  templateUrl: './smtp-edit.component.html',
  styleUrls: ['./smtp-edit.component.scss']
})
export class SmtpEditComponent implements OnInit {

  smtpId : string = '' ; 
  smtp : SmtpConfig = null ; 
  _editForm : FormGroup ;
  formIsSubmitted : boolean = false ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _formBuilder : FormBuilder, 
    private _toastr : ToastrService , 
    private _router : Router,
    private _smtpService : SmtpService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.smtpId = params['id'] ; 
        this.subscribeOnGetSmtpObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ;
  }

  subscribeOnGetSmtpObservable() {
    this._smtpService.getSmtpById(this.smtpId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.smtp = result.smtp ; 
          this.initEditForm() ; 
        } else if(result.success_code == 404) {
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.error(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      driver : [this.smtp.driver,[Validators.required]] , 
      host : [this.smtp.host,[Validators.required]] , 
      port : [this.smtp.port,[Validators.required]] , 
      username : [this.smtp.username,[Validators.required]] , 
      password : [this.smtp.password,[Validators.required]] ,
      name : [this.smtp.name,[Validators.required]] ,  
      from_address : [this.smtp.from_address,[Validators.required]] , 
      encryption : [this.smtp.encryption,[Validators.required]]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onSmtpUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      driver : this.getControlValue('driver') , 
      host : this.getControlValue('host')  , 
      port : this.getControlValue('port') , 
      encryption : this.getControlValue('encryption') , 
      username : this.getControlValue('username') , 
      password : this.getControlValue('password') ,
      name : this.getControlValue('name') , 
      from_address : this.getControlValue('from_address') , 
    }
    this.formIsSubmitted = true ; 
    this.subscribeOnSmtpUpdateObservable(request,this.smtpId) ; 

  }

  subscribeOnSmtpUpdateObservable(request : any , id : string) {
    this._smtpService.update(request,id).subscribe(
      (result) => { 
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
          this.smtp = result.smtp ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
