import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomTypesContentsService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
   }

  getList(customTypeId : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'custom-types-contents/'+customTypeId+'/get-list',{headers : tokenHeader}) ; 
  }

  getContentById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'custom-types-contents/'+id+'/get-content',{headers : tokenHeader}) ; 
  }

  store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'custom-types-contents/store',request,{headers : tokenHeader}) ;
    
  }

  update(request: any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'custom-types-contents/'+id+'/update',request,{headers : tokenHeader}) ; 
  }

  delete(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'custom-types-contents/'+id+'/delete',{headers : tokenHeader}) ;
  }
}
