import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationEmail } from 'src/app/models/notification-email.model';
import { CommonService } from 'src/app/services/common/common.service';
import { NotificationEmailsService } from 'src/app/services/notification-emails/notification-emails.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-notification-emails-index',
  templateUrl: './notification-emails-index.component.html',
  styleUrls: ['./notification-emails-index.component.scss']
})
export class NotificationEmailsIndexComponent implements OnInit {

  emails : NotificationEmail [] = null ; 
  searchWord : string = '' ; 
  p : number = 1 ; 

  constructor(
    private _emailsService : NotificationEmailsService,
    private _commonService : CommonService , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetEmailsObservable() ; 
  }

  subscribeOnGetEmailsObservable() {
    this._emailsService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.emails = result.emails ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this.searchWord == '' ? this.emails : this._commonService.filterArrayByTitleName(this.searchWord,this.emails,'email') ; 
  }

  onEmailDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cet email ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let email = this.emails.find(
          (em) => {
            return em.id == +id ; 
          }
        ) ; 
        this.emails.splice(this.emails.indexOf(email),1) ;
        this.subscribeOnEmailDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnEmailDeleteObservable(id : string) {
    this._emailsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
