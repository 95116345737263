import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UrlRedirection } from 'src/app/models/url-redirection.model';
import { UrlsRedirectionsService } from 'src/app/services/urls-redirections/urls-redirections.service';

@Component({
  selector: 'app-url-redirections-edit',
  templateUrl: './url-redirections-edit.component.html',
  styleUrls: ['./url-redirections-edit.component.scss']
})
export class UrlRedirectionsEditComponent implements OnInit {

  urlId : string = '' ; 
  url : UrlRedirection = null ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 


  constructor(
    private _formBuilder : FormBuilder , 
    private _toastr : ToastrService , 
    private _router : Router , 
    private _redirectionsService: UrlsRedirectionsService ,
    private _activatedRoute : ActivatedRoute

  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params)=> {
        this.urlId = params['id'] ; 
        this.subscribeOnGetUrlObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetUrlObservable() {
    this._redirectionsService.getUrlById(this.urlId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.url = result.url ; 
          this.initEditForm() ; 
        } else if(result.success_code == 404) {
          this._router.navigate(['/home','url-redirections','index']) ; 
          this._toastr.error(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      old_url : [this.url.old_url,[Validators.required]] ,
      new_url : [this.url.new_url,[Validators.required]] ,  
    } ; 
    this._editForm = this._formBuilder.group(group) ;  
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onUrlUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    if(!this.getControlValue('old_url').startsWith(this._redirectionsService.base) || !this.getControlValue('new_url').startsWith(this._redirectionsService.base) ) {
      this._toastr.error('Les 2 urls doivent commencer par le domaine de votre site') ; 
      return 0 ; 
    }
    if(this.getControlValue('old_url').endsWith('/') || this.getControlValue('new_url').endsWith('/')) {
      /* if(this.getControlValue('old_url') != this._redirectionsService.base && this.getControlValue('new_url') != this._redirectionsService.base) {
        this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
        return 0 ;
      }  */
      if(this.getControlValue('old_url') == this._redirectionsService.base && this.getControlValue('new_url') != this._redirectionsService.base) {
        if(this.getControlValue('new_url').endsWith('/')) {
          this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
          return 0 ;
        }
      } else if(this.getControlValue('new_url') == this._redirectionsService.base && this.getControlValue('old_url') != this._redirectionsService.base) {
        if(this.getControlValue('old_url').endsWith('/')) {
          this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
          return 0 ;
        }
      } else if(this.getControlValue('old_url') != this._redirectionsService.base && this.getControlValue('new_url') != this._redirectionsService.base) {
        if(this.getControlValue('old_url').endsWith('/') || this.getControlValue('new_url').endsWith('/')) {
          this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
          return 0 ;
        }
    }
    }
    let request = {
      old_url : this.getControlValue('old_url') ,
      new_url : this.getControlValue('new_url') , 
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnUrlUpdateObservable(request,this.urlId) ; 
  }

  subscribeOnUrlUpdateObservable(request : any,id : string) {
    this._redirectionsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','url-redirections','index'])  ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error)  ; 
      }
    ) ; 
  }

}
