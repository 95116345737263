import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import {Owner} from '../../models/owner.model' ; 

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  private _authStatus : boolean = false ; 
  private _authStatusSubject : Subject<boolean> = new Subject<boolean> () ;
  private _ownerSubject : Subject<Owner> = new Subject<Owner> () ; 
  private _owner : Owner ; 

  constructor(private _httpClient : HttpClient) {
    super() ;
   }

   login(request: any) {
    return this._httpClient.post<any>(this.baseUrl+'login' , request) ; 
   }

   logout() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
     return this._httpClient.get<any>(this.baseUrl+'logout',{headers : tokenHeader}) ; 
   }

   get authStatus() {
     return this._authStatus ; 
   }

   set authStatus(authStatus : boolean) {
    this._authStatus = authStatus ; 
    this.emitAuthStatusSubject() ; 
   }
   
   set owner(owner : Owner) {
     this._owner = owner ; 
     this.emitOwnerSubject() ; 
   }

   emitAuthStatusSubject() {
     this._authStatusSubject.next(this._authStatus) ; 
   }

   emitOwnerSubject() {
     this._ownerSubject.next(this._owner) ; 
   }

   get authStatusSubject() {
     return this._authStatusSubject ; 
   }

   get ownerSubject() {
     return this._ownerSubject ; 
   }


}
