import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TopMenuElement } from 'src/app/models/home-page/top-menu-element.model';
import { TopMenuService } from 'src/app/services/home-page/top-menu/top-menu.service';

@Component({
  selector: 'app-top-menu-elements-edit',
  templateUrl: './top-menu-elements-edit.component.html',
  styleUrls: ['./top-menu-elements-edit.component.scss']
})
export class TopMenuElementsEditComponent implements OnInit {

  element : TopMenuElement = null ; 
  elementId : string = '' ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _elementsService : TopMenuService,
    private _activatedRoute : ActivatedRoute , 
    private _toastr : ToastrService,
    private _router : Router , 
    private _formBuilder : FormBuilder
  ) { }

  ngOnInit() {
    
    this.subscribeOnActivatedRouteObservable() ;
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.elementId = params['id'] ;
        //alert(this.elementId); 
        this.subscribeOnGetElementObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetElementObservable() {
    this._elementsService.getElementById(this.elementId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','top-menu-elements','index']) ; 
          this._toastr.error(result.message) ; 
          //alert('ok') ; 
        } else if(result.success_code == 200) {
          this.element = result.element ; 
          //alert('ok') ;
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      label : [this.element.label,[Validators.required]] , 
      url : [this.element.url,[Validators.required]] , 
      status : [this.element.status,[Validators.required]]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ;
  }

  onStatusSwitch() {
    if(this.getControlValue('status') == 1) {
      this._editForm.patchValue({
        status : 0 ,
      }) ;
    } else if(this.getControlValue('status') == 0) {
      this._editForm.patchValue({
        status : 1 ,
      }) ;
    }
    //console.log(this.getControlValue('status')) ; 
  }

  onElementUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide!') ; 
      return 0 ; 
    }
    let request = {
      label : this.getControlValue('label') , 
      url : this.getControlValue('url') , 
      status : this.getControlValue('status') , 
    } ; 
    this.formIsSubmitted = true ;
    this.subscribeOnElementUpdateObservable(request,this.elementId) ;  
  }

  subscribeOnElementUpdateObservable(request : any , id : string) {
    this._elementsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','top-menu-elements','index']) ;
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;  
        }
      } ,
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
