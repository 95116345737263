import { Component, OnInit } from '@angular/core';
import { TrainingsService } from 'src/app/services/home-page/trainings/trainings.service';
import { ToastrService } from 'ngx-toastr';
import { Training } from 'src/app/models/home-page/training.model';

@Component({
  selector: 'app-trainings-index',
  templateUrl: './trainings-index.component.html',
  styleUrls: ['./trainings-index.component.scss']
})
export class TrainingsIndexComponent implements OnInit {

  trainings : Training [] = null ; 

  constructor(
    private _trainingsService : TrainingsService , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetTrainingsObservable() ;
  }

  subscribeOnGetTrainingsObservable() {
    this._trainingsService.getList().subscribe(
      (result) => {
        this.trainings = result.trainings ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
