import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CustomTypesCategoriesService } from 'src/app/services/custom-types-categories/custom-types-categories.service';
import { CustomTypesService } from 'src/app/services/custom-types/custom-types.service';
import { CustomTypeCategory } from 'src/app/models/custom-type-category.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

//declare function initSelect2() : any ; 

@Component({
  selector: 'app-custom-type-create',
  templateUrl: './custom-type-create.component.html',
  styleUrls: ['./custom-type-create.component.scss']
})
export class CustomTypeCreateComponent implements OnInit {

   _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ;
  validationErrors : string = null ;  
  //categories : CustomTypeCategory[] = null ; 

  constructor(
    private _formBuilder : FormBuilder,
    //private _categoriesService : CustomTypesCategoriesService,
    private _customTypesService : CustomTypesService , 
    private _toastr : ToastrService,
    private _router : Router
  ) { }

  ngOnInit() {
    //this.subscribeOnGetCategoriesObservable();
    this.initCreateForm() ;
  }

  initCreateForm() {
    let group = {
      label : ['',[Validators.required]] , 
      //categories: new FormArray([],Validators.required)
    } ; 
    this._createForm = this._formBuilder.group(group) ; 
  }

  /*subscribeOnGetCategoriesObservable() {
    this._categoriesService.getList().subscribe(
      (result) => {
        this.categories = result.categories ; 
        //initSelect2() ;
        this.initCreateForm() ;
      } , 
      (error) => {
        console.log(error) ; 
      }
    )
  }*/

  /*onCheck(event) {
    const formArray = this.getControl('categories') as FormArray ; 
    //formArray.push(new FormControl(event.target.value)) ;
    if(event.target.checked) {
      formArray.push(new FormControl(event.target.value)) ;
    } else {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value === event.target.value) {
            formArray.removeAt(index) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
    }
    //console.log(this.getControl('categories').value) ; 
  }*/

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this._createForm.get(controlName).value ; 
  }

  onCustomTypeCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    this.validationErrors = null ; 
    let request = {
      label : this.getControlValue('label') ,
      //categories : this.getControlValue('categories') 
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnStoreCustomTypeObservable(request) ; 
  }

  subscribeOnStoreCustomTypeObservable(request : any) {
    this._customTypesService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','custom-types','index']) ; 
          this._toastr.success(result.message) ;
          setTimeout(
            ()=>{
              window.location.href = "/home/custom-types/index";
            },500 )
          
        } else if(result.success_code == 400){
          this.validationErrors = result.message ; 
          this.formIsSubmitted = false ;
        }
        this.formIsSubmitted = false ;
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
