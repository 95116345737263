import { Component, OnInit } from '@angular/core';
import { Training } from 'src/app/models/home-page/training.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TrainingsService } from 'src/app/services/home-page/trainings/trainings.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-edit',
  templateUrl: './training-edit.component.html',
  styleUrls: ['./training-edit.component.scss']
})
export class TrainingEditComponent implements OnInit {

  training : Training = null ; 
  trainingId : string = '' ; 
  private _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ;

  constructor(
    private _formBuilder : FormBuilder , 
    private _router : Router , 
    private _trainingsService : TrainingsService ,
    private _activatedRoute : ActivatedRoute , 
    private _toastr : ToastrService

  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable();
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.trainingId = params['id'] ;
        this.subscribeOnGetTrainingObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ); 
  }

  subscribeOnGetTrainingObservable() {
    this._trainingsService.getTrainingById(this.trainingId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','trainings','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.training = result.training ;
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.training.title,[Validators.required]] , 
      text : [this.training.text] , 
      url : [this.training.url]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this._editForm.get(controlName).value ; 
  }

  onTrainingUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      title : this.getControlValue('title') ,
      text : this.getControlValue('text') , 
      url : this.getControlValue('url') ,
    }
    this.formIsSubmitted = true ; 
    this.subscribeOnUpdateTrainingObservable(request,this.trainingId) ;
  }

  subscribeOnUpdateTrainingObservable(request : any , id : string) {
    this._trainingsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','trainings','index']) ;
          this.formIsSubmitted = false; 
          this._toastr.success(result.message) ;  
        }
      },
      (error) => {
        console.log(error) ;
      }
    ) ;
  }

}
