import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MenusService } from 'src/app/services/menus/menus.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {

  @Input() tree : any[]  ; 
  
  constructor(
    private _menusService : MenusService , 
    private _toastr : ToastrService 
  ) { }

  ngOnInit() {
  }

  onDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cet élément ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        /* let customType = this.customTypes.find(
          (type) => {
            return type.id == +id ; 
          }
        ) ; */ 
        //this.customTypes.splice(this.customTypes.indexOf(customType),1) ;
        this.subscribeOnElementDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnElementDeleteObservable(id : string) {
    this._menusService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
          /* setTimeout(
            () => {
              window.location.href = "/home/menus/index" ; 
            } , 500
          ) ; */
          this.removeFromArray(id,this.tree) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  removeFromArray(id : string,array) {
    for(let i = 0 ; i<array.length ; i++)  {
      if(array[i].id == id) {
        array.splice(array.indexOf(array[i]),1) ; 
      } else {
        if(array[i].sub_menus) {
          this.removeFromArray(id,array[i].sub_menus) ; 
        }
      }
    }
  }

  sortArrayByChildRank(array : any[]) {
    if(array != null && array.length > 0) {
      array.sort(
        (a,b)=> {
          return a.child_rank > b.child_rank ? 1 : -1 ;
        }
      )
      return array ;
    }
  }



}
