import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Heading } from 'src/app/models/heading.model';
import { CommonService } from 'src/app/services/common/common.service';
import { HeadingsService } from 'src/app/services/headings/headings.service';

@Component({
  selector: 'app-headings-edit',
  templateUrl: './headings-edit.component.html',
  styleUrls: ['./headings-edit.component.scss']
})
export class HeadingsEditComponent implements OnInit {

  headingId : string = '' ; 
  heading : Heading = null ; 
  validationErrors : string = null ; 
  formIsSubmitted : boolean = false ; 
  _editForm : FormGroup ; 
  generatedSlug : string = '' ; 

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _headingsService : HeadingsService,
    private _toastr : ToastrService,
    private _router : Router,
    private _formBuilder : FormBuilder,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.headingId = params['id'] ;
        this.subscribeOnGetHeadingObservable(); 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetHeadingObservable() {
    this._headingsService.getHeadingById(this.headingId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.heading = result.heading ; 
          this.generatedSlug = this.heading.slug ; 
          this.initEditForm() ; 
        } else if(result.success_code == 404) {
          this._router.navigate(['/home','headings','index']) ;
          this._toastr.error(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.heading.title,[Validators.required]] ,
      display_title : [this.heading.display_title,[Validators.required]] , 
      description : [this.heading.description,[Validators.required]] , 
      image : [''] ,
      alt_image : [this.heading.alt_image] ,  
      meta_title : [this.heading.meta_title] , 
      meta_description : [this.heading.meta_description] , 
      keywords : [this.heading.keywords]
    }
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._editForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ; 
       
    }
  }

  onHeadingUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 
    let title = this.getControlValue('title') ; 
    let display_title = this.getControlValue('display_title') ; 
    let description = this.getControlValue('description') ; 
    let image = this.getControlValue('image') ; 
    let meta_title = this.getControlValue('meta_title') ; 
    let meta_description = this.getControlValue('meta_description') ; 
    let keywords = this.getControlValue('keywords') ;
    let alt_image = this.getControlValue('alt_image') ;  

    formData.append('title',title) ; 
    formData.append('description',description) ; 
    formData.append('image',image) ;
    formData.append('meta_title',meta_title) ; 
    formData.append('meta_description',meta_description) ; 
    formData.append('keywords',keywords) ;
    formData.append('alt_image',alt_image) ; 
    formData.append('display_title',display_title) ; 
     
    this.formIsSubmitted= true ; 
    this.subscribeOnHeadingUpdateObservable(formData,this.headingId) ; 
  }

  subscribeOnHeadingUpdateObservable(request : any , id : string) {
    this._headingsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {  
          this._router.navigate(['/home','headings','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        } else if(result.success_code == 400) {
          this.validationErrors = result.message ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  domain() {
    return this._headingsService.siteDomain ; 
  }

  onUrlTitleChange(event) {
    if(event.target.value.trim().length == 0) {
      this.generatedSlug = '' ; 
      return 0 ; 
    } 
    this.getGeneratedSlug(event.target.value) ; 
  }
  getGeneratedSlug(text : string) {
    this._commonService.getSlug(text).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.generatedSlug = result.slug ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }



}
