import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SocialMedia } from 'src/app/models/home-page/social-media.model';
import { SocialMediaService } from 'src/app/services/home-page/social-media/social-media.service';

@Component({
  selector: 'app-social-media-edit',
  templateUrl: './social-media-edit.component.html',
  styleUrls: ['./social-media-edit.component.scss']
})
export class SocialMediaEditComponent implements OnInit {

  media : SocialMedia = null ; 
  mediaId : string = '' ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _mediasService : SocialMediaService,
    private _formBuilder : FormBuilder,
    private _router : Router,
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.mediaId = params['id'] ; 
        this.subscribeOnGetMediaObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetMediaObservable() {
    this._mediasService.getMediaById(this.mediaId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','social-media','index']) ;
          this._toastr.error(result.message) ;  
        } else if(result.success_code == 200) {
          this.media = result.media ;
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      url : [this.media.url,[Validators.required]] , 
      status: [this.media.status,[Validators.required]]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onStatusSwitch() {
    if(this.getControlValue('status') == 1) {
      this._editForm.patchValue({
        status : 0 ,
      }) ;
    } else if(this.getControlValue('status') == 0) {
      this._editForm.patchValue({
        status : 1 ,
      }) ;
    }
    //console.log(this.getControlValue('status')) ; 
  }

  onMediaUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ;
      return 0 ;  
    }
    let request = {
      url : this.getControlValue('url') , 
      status : this.getControlValue('status')
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnMediaUpdateObservable(request,this.mediaId) ; 
  }

  subscribeOnMediaUpdateObservable(request : any , id : string) {
    this._mediasService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','social-media','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
