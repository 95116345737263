import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FooterBloc } from 'src/app/models/home-page/footer-bloc.model';
import { CommonService } from 'src/app/services/common/common.service';
import { FooterBlocsService } from 'src/app/services/home-page/footer-blocs/footer-blocs.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-footer-blocs-index',
  templateUrl: './footer-blocs-index.component.html',
  styleUrls: ['./footer-blocs-index.component.scss']
})
export class FooterBlocsIndexComponent implements OnInit {

  blocs : FooterBloc[] = null ;
  searchWord : string = '' ;  

  constructor(
    private _blocsService : FooterBlocsService , 
    private _commonService : CommonService,
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetBlocsObservable() ; 
  }

  subscribeOnGetBlocsObservable() {
    this._blocsService.getList().subscribe(
      (result) => {
        if(result.success_code== 200) {
          this.blocs = result.blocs ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ;
  }

  onBlocDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer ce bloc ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let bloc = this.blocs.find(
          (b) => {
            return b.id == +id ; 
          }
        ) ; 
        this.blocs.splice(this.blocs.indexOf(bloc),1) ;
        this.subscribeOnBlocDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnBlocDeleteObservable(id : string) {
    this._blocsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredBlocsArray() {
    return this._commonService.filterContentsArray(this.searchWord,this.blocs) ; 
  }

}
