import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Menu } from 'src/app/models/menu.model';
import { MenusService } from 'src/app/services/menus/menus.service';

@Component({
  selector: 'app-menus-edit',
  templateUrl: './menus-edit.component.html',
  styleUrls: ['./menus-edit.component.scss']
})
export class MenusEditComponent implements OnInit,OnDestroy {

  element : Menu = null ; 
  elementId : string = '' ; 
  allElements : Menu[] = null ;
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ;
  tree : any[] = null ;  
  elementsForSelect : any [] = [] ; 
  childs : any [] = null;
  dragulaSubscription = new Subscription() ;   

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _menusService : MenusService,
    private _toastr : ToastrService , 
    private _router :  Router , 
    private _formBuilder : FormBuilder,
    private dragulaService : DragulaService
  ) { }

  ngOnInit() {
     
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.elementId = params['id'] ;
        this.subscribeOnGetElementObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetElementObservable() {
    this._menusService.getElementById(this.elementId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','menus','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.element = result.menu ; 
          this.childs = result.children ; 
          //console.log(this.childs) ; 
          this.subscribeOnGetElementsObservable() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetElementsObservable() {
    this._menusService.getList().subscribe(
      (result) => {
        this.allElements = result.menus ;
        //this.removeCurrentElementFromArrayAndInitForm() ; 
        //this.initEditForm() ; 
        this.subscribeOnGetTreeObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTreeObservable() {
    this._menusService.getTree().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.tree = result.tree ;
          //console.log(this.tree) ; 
          this.recursiveSearch(this.tree,this.elementId) ; 
          //this.removeCurrentElementFromArrayAndInitForm() ;
           this.getStayedElements(this.tree) ; 
           this.initDragulaDragAndDrop() ;
           this.subscribeOnDragulaEvent() ;  
          this.initEditForm() ; 
            
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  //to re-implement
  /* removeCurrentElementFromArrayAndInitForm() {
    for(let i =0 ; i<this.allElements.length ; i++) {
      if(this.element.id === this.allElements[i].id || this.element.id === this.allElements[i].parent_id ) {
        this.allElements.splice(this.allElements.indexOf(this.allElements[i]),1) ; 
      }
    }
    this.initEditForm() ;   
  } */

  recursiveSearch(elements,parent_id) {
     
    for(let branch of elements) {
      if(branch.id == parent_id) {
        elements.splice(elements.indexOf(branch),1) ; 
      } else {
        if(branch.sub_menus) {
          this.recursiveSearch(branch.sub_menus,parent_id) ;
        }
      }
    }
    //console.log(elements) ; 
  }

  getStayedElements(elements) {
     
    for(let branch of elements) {
      this.elementsForSelect.push(branch) ; 
      if(branch.sub_menus) {
        this.getStayedElements(branch.sub_menus) ; 
      }
    }
    //console.log(this.elementsForSelect) ; 
    
  }

  initEditForm() {
    let group = {
      title : [this.element.title,[Validators.required]] , 
      parent_id : [this.element.parent_id],
      url : [this.element.url] , 
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onElementUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let numberOfParents = 0 ; 
    for(let elem of this.allElements) {(elem.parent_id == null) && numberOfParents ++ ;}
    if(numberOfParents > 6 && this.getControlValue('parent_id') == 0 && this.element.parent_id != null) {
      this._router.navigate(['/home','menus','index']) ; 
      this._toastr.error('Vous ne pouvez pas dépasser 7 éléments parents') ; 
      return 0 ; 
    }
    let request  = {
      title : this.getControlValue('title') , 
      parent_id : this.getControlValue('parent_id') ,
      url : this.getControlValue('url')
    } ;
    this.formIsSubmitted = true ; 
    this.subscribeOnElementUpdateObservable(request,this.elementId) ;  
  }

  subscribeOnElementUpdateObservable(request : any , id : string) {
    this._menusService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','menus','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  ngOnDestroy() : void {
    this.dragulaService.destroy('SPILL') ; 
    this.dragulaSubscription.unsubscribe() ;
  }

  initDragulaDragAndDrop() {
    this.dragulaService.createGroup("SPILL", {
      removeOnSpill: false,
    });
  }

  subscribeOnDragulaEvent() {
    this.dragulaSubscription =  this.dragulaService.drop('SPILL').subscribe(
      ({name,el,source}) => { 
        let selectedClasses = document.querySelectorAll('.to-sort') ;
        let elementsIds = [] ;    
        selectedClasses.forEach(function(element,key){
          elementsIds.push(element.id) ; 
        }) ;
        let lastIndex = elementsIds.length - 1  ; 
        elementsIds.splice(lastIndex,1) ;
        //console.log(elementsIds) ;  
        let request = {
          elements_ids : elementsIds
        } ; 
        this.subscribeOnUpdateChildsRanksObservable(request) ; 
      }
    ) ;
  }

  subscribeOnUpdateChildsRanksObservable(request : any) {
    this._menusService.updateChildsRanks(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onElementChange(id : string) {
    /* this._router.navigate(['/home','menus','index']) ; 
    this._router.navigate(['/home','menus',id,'edit']) ; */
    window.location.href = "/home/menus/"+id+"/edit" ;  
  } 

}
