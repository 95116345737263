import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterBloc } from 'src/app/models/home-page/footer-bloc.model';
import { FooterBlocsElementsService } from 'src/app/services/home-page/footer-blocs-elements/footer-blocs-elements.service';
import { FooterBlocsService } from 'src/app/services/home-page/footer-blocs/footer-blocs.service';

@Component({
  selector: 'app-footer-elements-create',
  templateUrl: './footer-elements-create.component.html',
  styleUrls: ['./footer-elements-create.component.scss']
})
export class FooterElementsCreateComponent implements OnInit {

  blocId : string = null ; 
  bloc : FooterBloc = null ; 
  show : boolean = false ; 
  formIsSubmitted : boolean = false ;
  private _createForm : FormGroup 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _blocsService : FooterBlocsService ,
    private _router : Router,
    private _toastr : ToastrService,
    private _elementsService : FooterBlocsElementsService,
    private _formBuilder : FormBuilder
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.blocId = params['blocId'] ; 
        this.subscribeOnGetBlocObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ;
  }

  subscribeOnGetBlocObservable() {
    this._blocsService.getBlocById(this.blocId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','footer-blocs','index']) ; 
          this._toastr.error(result.message) ; 
        } else {
          this.bloc = result.bloc ; 
          this.subscribeOnGetElementsObservable() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetElementsObservable() {
    this._elementsService.getList(this.blocId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          if(this.bloc.type == 0) {
            if(result.elements.length > 9) {
              this._router.navigate(['/home','home-page','footer-elements',this.bloc.id,'index']) ; 
              this._toastr.error('Vous avez atteint votre limite de création des menus pour ce bloc') ; 
            } else {
              this.show = true ; 
              this.initCreateForm() ; 
            }
          } else if(this.bloc.type == 1) {
            if(result.elements.length > 0) {
              this._router.navigate(['/home','home-page','footer-elements',this.bloc.id,'index']) ; 
              this._toastr.error('Vous avez atteint votre limite de création des description pour ce bloc') ;
            } else {
              this.show = true ; 
              this.initCreateForm() ; 
            }
          }
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initCreateForm() {
    let group = {} ; 
    if(this.bloc.type == 0) {
      group = {
        content : ['',[Validators.required]] , 
        url : ['',Validators.required] ,
        footer_bloc_id : [this.bloc.id,[Validators.required]]
      } ; 
    } else if(this.bloc.type == 1) {
      group = {
        content : ['',Validators.required] , 
        footer_bloc_id : [this.bloc.id,[Validators.required]]
      } ; 
    }
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onElementCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {} ; 
    if(this.bloc.type == 0) {
      request = {
        content : this.getControlValue('content') ,
        url : this.getControlValue('url'),
        footer_bloc_id : this.getControlValue('footer_bloc_id')
      } ; 
    } else if(this.bloc.type == 1) {
      request = {
        content : this.getControlValue('content') , 
        footer_bloc_id : this.getControlValue('footer_bloc_id')
      } ; 
    }
    this.formIsSubmitted =  true ; 
    this.subscribeOnStoreElementObservable(request) ; 
  }

  subscribeOnStoreElementObservable(request : any) {
    this._elementsService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','footer-elements',this.bloc.id,'index']) ;
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
