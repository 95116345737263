import { Component, OnInit } from '@angular/core';
import { International } from 'src/app/models/home-page/international.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { InternationalService } from 'src/app/services/home-page/international/international.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-international-edit',
  templateUrl: './international-edit.component.html',
  styleUrls: ['./international-edit.component.scss']
})
export class InternationalEditComponent implements OnInit {

  international : International = null ; 
  formIsSubmitted : boolean  = false ; 
  private _editForm : FormGroup ; 
  internationalId : string = '' ; 

  constructor(
    private _internationalsService : InternationalService , 
    private _toastr : ToastrService , 
    private _router : Router , 
    private _activatedRoute : ActivatedRoute ,
    private _formBuilder : FormBuilder 
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.internationalId = params['id'] ;
        this.subscribeOnGetInternationalObservable(); 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetInternationalObservable() {
    this._internationalsService.getInternationalById(this.internationalId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','home-page','international','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.international = result.international ;
          
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ;
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.international.title,[Validators.required]] , 
      text : [this.international.text,[Validators.required]] , 
      image : '' , 
      alt_image : [this.international.alt_image] , 
      url : [this.international.url , [Validators.required]] , 
    }
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._editForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ;   
    }
  }

  onInternationalUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 
    let title = this.getControlValue('title') ; 
    let text = this.getControlValue('text') ; 
    let image = this.getControlValue('image') ;
    let url = this.getControlValue('url') ;  
    let alt_image = this.getControlValue('alt_image') ; 

    formData.append('title',title) ; 
    formData.append('text',text) ; 
    formData.append('image',image) ;
    formData.append('url',url) ; 
    formData.append('alt_image',alt_image) ;  
    this.formIsSubmitted = true ; 
    this.subscribeOnInternationalUpdateObservable(formData,this.internationalId) ; 
  }

  subscribeOnInternationalUpdateObservable(request : any , id : string) {
    this._internationalsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','home-page','international','index']) ;
          this.formIsSubmitted = false; 
          this._toastr.success(result.message) ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
