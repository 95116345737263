import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Faq } from 'src/app/models/faq.model';
import { CommonService } from 'src/app/services/common/common.service';
import { FaqsService } from 'src/app/services/faqs/faqs.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-faq-index',
  templateUrl: './faq-index.component.html',
  styleUrls: ['./faq-index.component.scss']
})
export class FaqIndexComponent implements OnInit {

  faqs : Faq[] = null ; 
  searchWord : string = '' ; 

  constructor(
    private _faqsService : FaqsService,
    private _commonService : CommonService,
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetFaqsObservable() ; 
  }

  subscribeOnGetFaqsObservable() {
    this._faqsService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.faqs = result.faqs ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this.searchWord == '' ? this.faqs : 
    this._commonService.filterArrayByTitleName(this.searchWord,this.faqs,'question') ; 
  }

  onFaqDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cette question ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let question = this.faqs.find(
          (faq) => {
            return faq.id == +id ; 
          }
        ) ; 
        this.faqs.splice(this.faqs.indexOf(question),1) ;
        this.subscribeOnFaqDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnFaqDeleteObservable(id : string) {
    this._faqsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

}
