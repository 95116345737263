import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Faq } from 'src/app/models/faq.model';
import { FaqsService } from 'src/app/services/faqs/faqs.service';

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.scss']
})
export class FaqEditComponent implements OnInit {

  faqId : string = null ; 
  faq : Faq = null ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _formBuilder : FormBuilder , 
    private _faqsService : FaqsService , 
    private _activatedRoute : ActivatedRoute , 
    private _router : Router , 
    private _toastr : ToastrService

  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.faqId = params['id'] ; 
        this.subscribeOnGetFaqObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetFaqObservable() {
    this._faqsService.getFaqById(this.faqId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','faqs','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.faq = result.faq ;
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      question : [this.faq.question,[Validators.required]] , 
      response : [this.faq.response,[Validators.required]]
    } ;
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onFaqUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      question : this.getControlValue('question') , 
      response : this.getControlValue('response')
    } ;
    this.formIsSubmitted = true ;  
    this.subscribeOnUpdateFaqObservable(request,this.faqId) ; 
  }

  subscribeOnUpdateFaqObservable(request : any , id : string) {
    this._faqsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','faqs','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
