import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TrainingType } from 'src/app/models/training-type.model';
import { Training } from 'src/app/models/training.model';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingContentsTypesService } from 'src/app/services/trainings/training-contents-types/training-contents-types.service';
import { TrainingsService } from 'src/app/services/trainings/trainings/trainings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-trainings-contents-types-index',
  templateUrl: './trainings-contents-types-index.component.html',
  styleUrls: ['./trainings-contents-types-index.component.scss']
})
export class TrainingsContentsTypesIndexComponent implements OnInit {

  types : TrainingType[] = null ; 
  trainingId : string ='' ; 
  training : Training = null ; 
  searchWord : string = '' ; 

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _typesService : TrainingContentsTypesService , 
    private _trainingsService : TrainingsService , 
    private _router : Router,
    private _toastr : ToastrService,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.trainingId = params['trainingId'] ;
        this.subscribeOnGetTrainingObservable() ;  
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTrainingObservable() {
    this._trainingsService.getTrainingById(this.trainingId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.training = result.education ; 
          this.subscribeOnGetTypesObservable() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTypesObservable() {
    this._typesService.getList(this.trainingId).subscribe(
      (result) => {
        this.types = result.types ; 
        
      } , 
      (error) => {
        console.log(error) ;
      }
    ) ; 
  }

  filteredArray() {
    return this.searchWord == '' ? this.types : this._commonService.filterArrayByTitleName(this.searchWord,this.types,'label') ;  
  }

  onTypeDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer ce type ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let type = this.types.find(
          (t) => {
            return t.id == +id ; 
          }
        ) ; 
        this.types.splice(this.types.indexOf(type),1) ;
        this.subscribeOnTypeDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnTypeDeleteObservable(id : string) {
    this._typesService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ); 
  }

}
