import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingContentsTypesService extends BaseService {
  
  constructor(private _http : HttpClient) {
    super() ; 
   }

   getList(trainingId : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'educations-types/'+trainingId+'/get-list',{headers : tokenHeader}) ; 
   }

   getTypeById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'educations-types/'+id+'/get-type',{headers : tokenHeader}) ; 
   }

   store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'educations-types/store',request,{headers : tokenHeader}) ; 
   }

   update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'educations-types/'+id+'/update',request,{headers : tokenHeader}) ; 
   }

   delete(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'educations-types/'+id+'/delete',{headers : tokenHeader}) ; 
   }
}
