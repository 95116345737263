import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  _editForm : FormGroup ;
  formIsSubmitted : boolean = false ; 
  oldPasswordError : string = null ;  

  constructor(
    private _profileService : ProfileService , 
    private _formBuilder : FormBuilder , 
    private _toastr : ToastrService , 
    private _router : Router , 
  ) { }

  ngOnInit() {
    this.initEditForm() ; 
  }

  initEditForm() {
    let group = {
      old_password : ['',[Validators.required,Validators.minLength(8)]] , 
      password : ['',[Validators.required,Validators.minLength(8)]] , 
      password_confirmation : ['' ]
    }
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onPasswordUpdate() {
    if(this._editForm.invalid || this.getControlValue('password') != this.getControlValue('password_confirmation')) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    } 
    let request = {
      old_password : this.getControlValue('old_password') , 
      password : this.getControlValue('password') , 
      password_confirmation : this.getControlValue('password_confirmation')
    }
    this.formIsSubmitted = true ; 
    this.oldPasswordError = null ; 
    this.subscribeOnUpdatePasswordObservable(request) ; 
  }

  subscribeOnUpdatePasswordObservable(request : any) {
    this._profileService.updatePassword(request).subscribe(
      (result) => {
        if(result.success_code == 400) {
          this.formIsSubmitted = false ; 
          this.oldPasswordError = result.message ; 
          this.initEditForm() ; 
        } else if(result.success_code == 200) {
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      }, 
      (error) => {
        console.log(error) ; 
      }

    ) ; 
  }

}
