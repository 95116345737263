import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
   }

   getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'social-media/get-list',{headers : tokenHeader}) ; 
   }

   getMediaById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'social-media/'+id+'/get-media',{headers : tokenHeader}) ; 
   }

   update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'social-media/'+id+'/update',request,{headers : tokenHeader}) ; 
   }
}
