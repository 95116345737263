import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CtContentsCategory } from 'src/app/models/ct-contents-category.model';
import { CustomTypeContent } from 'src/app/models/custom-type-content.model';
import { CustomType } from 'src/app/models/custom-type.model';
import { CommonService } from 'src/app/services/common/common.service';
import { CtContentsCategoriesService } from 'src/app/services/ct-contents-categories/ct-contents-categories.service';
import { CustomTypesContentsService } from 'src/app/services/custom-types-contents/custom-types-contents.service';
import { CustomTypesService } from 'src/app/services/custom-types/custom-types.service';

@Component({
  selector: 'app-custom-type-content-edit',
  templateUrl: './custom-type-content-edit.component.html',
  styleUrls: ['./custom-type-content-edit.component.scss']
})
export class CustomTypeContentEditComponent implements OnInit {

  contentId : string = '' ; 
  content : CustomTypeContent = null  ; 
  _editForm : FormGroup ; 
  categories : CtContentsCategory[] = null ; 
  contentCategories : number[] = null ; 
  formIsSubmitted : boolean = false ; 
  generatedSlug : string = '' ; 
  customType : CustomType = null ; 

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _contentsService : CustomTypesContentsService,
    private _router : Router,
    private _toastr : ToastrService,
    private _formBuilder : FormBuilder,
    private _categoriesService : CtContentsCategoriesService,
    private _commonService : CommonService,
    private _customTypesService : CustomTypesService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable();
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.contentId = params['id'] ;
        this.subscribeOnGetContentObservable() 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetContentObservable() {
    this._contentsService.getContentById(this.contentId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','custom-types','index']) ; 
          this._toastr.error(result.message) ;
        } else if(result.success_code == 200) {
          this.content = result.content ;
          this.generatedSlug = this.content.slug ; 
          this.subscribeOnGetParentObservable(''+this.content.custom_type_id) ; 
          this.contentCategories = result.checked_categories ;
          //console.log(this.contentCategories) ;   
          this.subscribeOnGetCategoriesObservable() ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetParentObservable(customTypeId : string) {
    this._customTypesService.getCustomTypeById(customTypeId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.customType = result.custom_type ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetCategoriesObservable() {
    this._categoriesService.getList(JSON.stringify(this.content.custom_type_id)).subscribe(
      (result) => {
        this.categories = result.categories ;
        //console.log(this.categories); 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.content.title,[Validators.required]] , 
      display_title : [this.content.display_title,[Validators.required]] ,
      image : '' ,
      alt_image : [this.content.alt_image] ,  
      content : [this.content.content] , 
      status : [this.content.status,[Validators.required]] ,
      categories : new FormArray([],Validators.required),
      meta_title : [this.content.meta_title] , 
      meta_description : [this.content.meta_description] , 
      keywords : [this.content.keywords]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
    const formArray = this.getControl('categories') as FormArray ;
    for(let i= 0 ; i< this.contentCategories.length ; i++) {
      formArray.push(new FormControl(this.contentCategories[i])) ; 
    }
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ;
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._editForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ;   
    }
  }

  onStatusSwitch() {
    if(this.getControlValue('status') == 1) {
      this._editForm.patchValue({
        status : 0 ,
      }) ;
    } else if(this.getControlValue('status') == 0) {
      this._editForm.patchValue({
        status : 1 ,
      }) ;
    }
    //console.log(this.getControlValue('status')) ; 
  }

    onCheck(event) {
    const formArray = this.getControl('categories') as FormArray ; 
    if(event.target.checked) {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value == event.target.value) {
            formArray.removeAt(index) ;
            formArray.push(new FormControl(event.target.value)) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
      if(index == formArray.controls.length) {
        formArray.push(new FormControl(event.target.value)) ; 
      }
      
    } else {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value == event.target.value) {
            formArray.removeAt(index) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
    }
    //console.log(this.getControl('categories').value) ; 
  }

  onContentUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 
    let title = this.getControlValue('title') ; 
    let display_title = this.getControlValue('display_title') ; 
    let image = this.getControlValue('image') ; 
    let alt_image  = this.getControlValue('alt_image') ; 
    let content = this.getControlValue('content') ; 
    let categories = this.getControlValue('categories') ; 
    let status = this.getControlValue('status') ;
    let meta_title = this.getControlValue('meta_title') ; 
    let meta_description = this.getControlValue('meta_description') ; 
    let keywords = this.getControlValue('keywords') ;  
    formData.append('title',title) ; 
    formData.append('image',image) ; 
    formData.append('alt_image',alt_image) ; 
    formData.append('content',content) ; 
    formData.append('categories',categories) ; 
    formData.append('status',status) ; 
    formData.append('meta_title',meta_title) ; 
    formData.append('meta_description',meta_description) ;
    formData.append('keywords',keywords) ;
    formData.append('display_title',display_title) ; 
    
    this.formIsSubmitted = true; 
    this.subscribeOnContentUpdateObservable(formData,this.contentId) ;
  }

  subscribeOnContentUpdateObservable(request : any , id : string) {
    this._contentsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','custom-type-content',this.content.custom_type_id,'index']) ; 
          this.formIsSubmitted = false ; 
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onEditorFileChange(event,fileBrowser) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl) {
    let editorValue = this.getControlValue('content') ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ; 
    let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px !important; width:100% !important" />' ; 
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl('content').setValue(finalEditorValue) ; 
  }

  domain() {
    return this._contentsService.siteDomain ; 
  }

  onUrlTitleChange(event) {
    if(event.target.value.trim().length == 0) {
      this.generatedSlug = '' ; 
      return 0 ; 
    } 
    this.getGeneratedSlug(event.target.value) ; 
  }
  getGeneratedSlug(text : string) {
    this._commonService.getSlug(text).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.generatedSlug = result.slug ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
