import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PageCategory } from 'src/app/models/page-category.model';
import { Page } from 'src/app/models/page.model';
import { CommonService } from 'src/app/services/common/common.service';
import { PagesCategoriesService } from 'src/app/services/pages-categories/pages-categories.service';
import { PagesService } from 'src/app/services/pages/pages.service';

@Component({
  selector: 'app-pages-edit',
  templateUrl: './pages-edit.component.html',
  styleUrls: ['./pages-edit.component.scss']
})
export class PagesEditComponent implements OnInit {

  page : Page = null ; 
  pageId : string = '' ;
  categories : PageCategory[] = null ;
  checkedCategories : number[] = null ;
  formIsSubmitted : boolean = false ; 
  _editForm : FormGroup  
  generatedSlug : string = '' ; 

  constructor(
    private _activatedRoute : ActivatedRoute ,
    private _toastr : ToastrService,
    private _router : Router,
    private _categoriesService : PagesCategoriesService,
    private _pagesService : PagesService , 
    private _formBuilder : FormBuilder,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.pageId = params['id'] ; 
        this.subscribeOnGetPageObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetPageObservable() {
    this._pagesService.getPageById(this.pageId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','pages','index']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.page = result.page ;
          this.generatedSlug = this.page.slug ;  
          this.checkedCategories = result.checked_categories ;
          this.subscribeOnGetCategoriesObservable() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetCategoriesObservable() {
    this._categoriesService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.categories = result.categories ;
          this.initEditForm() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.page.title,[Validators.required]] , 
      display_title : [this.page.display_title,[Validators.required]] ,
      description : [this.page.description,Validators.required] , 
      content : [this.page.content,Validators.required] , 
      meta_title : [this.page.meta_title] , 
      meta_description : [this.page.meta_description] , 
      keywords : [this.page.keywords] , 
      categories: new FormArray([],Validators.required),
    } ;
    this._editForm = this._formBuilder.group(group) ; 
    const formArray = this.getControl('categories') as FormArray ;
    for(let i= 0 ; i< this.checkedCategories.length ; i++) {
      formArray.push(new FormControl(this.checkedCategories[i])) ; 
    }
  }

    onCheck(event) {
    const formArray = this.getControl('categories') as FormArray ; 
    if(event.target.checked) {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value == event.target.value) {
            formArray.removeAt(index) ;
            formArray.push(new FormControl(event.target.value)) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
      if(index == formArray.controls.length) {
        formArray.push(new FormControl(event.target.value)) ; 
      }
      
    } else {
      let index = 0 ; 
      formArray.controls.forEach(
        (control : FormControl) => {
          if(control.value == event.target.value) {
            formArray.removeAt(index) ; 
            return ; 
          }
          index ++ ; 
        }
      ) ;
    }
    //console.log(this.getControl('categories').value) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onPageUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      title : this.getControlValue('title') ,
      display_title : this.getControlValue('display_title')  , 
      description : this.getControlValue('description') , 
      content : this.getControlValue('content')  , 
      meta_title : this.getControlValue('meta_title') , 
      meta_description : this.getControlValue('meta_description') , 
      keywords : this.getControlValue('keywords') , 
      categories : this.getControlValue('categories')
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnPageUpdateObservable(request,this.pageId) ; 

  }

  subscribeOnPageUpdateObservable(request : any , id : string) {
    this._pagesService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
            this._router.navigate(['/home','pages','index']) ; 
            this._toastr.success(result.message) ; 
            this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onFileChange(event,fileBrowser) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl) {
    let editorValue = this.getControlValue('content') ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ; 
    let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px !important; width:100% !important" />' ; 
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl('content').setValue(finalEditorValue) ; 
  }

  domain() {
    return this._pagesService.siteDomain ; 
  }

  onUrlTitleChange(event) {
    if(event.target.value.trim().length == 0) {
      this.generatedSlug = '' ; 
      return 0 ; 
    } 
    this.getGeneratedSlug(event.target.value) ; 
  }
  getGeneratedSlug(text : string) {
    this._commonService.getSlug(text).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.generatedSlug = result.slug ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
