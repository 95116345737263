import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotificationEmailsService } from 'src/app/services/notification-emails/notification-emails.service';

@Component({
  selector: 'app-notification-emails-create',
  templateUrl: './notification-emails-create.component.html',
  styleUrls: ['./notification-emails-create.component.scss']
})
export class NotificationEmailsCreateComponent implements OnInit {

  _createForm : FormGroup  ; 
  formIsSubmitted : boolean = false ; 
  validationErrors : string = null ; 

  constructor(
    private _router : Router , 
    private _toastr : ToastrService,
    private _formBuilder : FormBuilder,
    private _emailsService : NotificationEmailsService
  ) { }

  ngOnInit() {
    this.initCreateForm() ; 
  }

  initCreateForm() {
    let group = {
      email : ['',[Validators.required,Validators.email]] , 
    }
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onEmailCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      email : this.getControlValue('email')  , 
    }
    this.formIsSubmitted = true ; 
    this.validationErrors = null ; 
    this.subscribeOnEmailCreateObservable(request) ; 
  }

  subscribeOnEmailCreateObservable(request : any) {
    this._emailsService.store(request).subscribe(
      (result) => { 
        if(result.success_code == 400) {
          this.validationErrors = result.message ; 
          this.formIsSubmitted = false ; 
        } else if(result.success_code == 200) {
          this._router.navigate(['/home','notification-emails','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
