import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class HeadingItemsService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
   }
  
  
  getList(headingId) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'heading-items/'+headingId+'/get-list',{headers : tokenHeader}) ; 
  }

  getItemById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'heading-items/'+id+'/get-item',{headers : tokenHeader}) ; 
  }


  store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'heading-items/store',request,{headers : tokenHeader}) ; 
  }

  update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'heading-items/'+id+'/update',request,{headers : tokenHeader}) ; 
  }

  delete(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'heading-items/'+id+'/delete',{headers : tokenHeader}) ; 
  }
}
