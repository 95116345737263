import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class UrlsRedirectionsService extends BaseService {

  constructor(private _http :  HttpClient) {
    super() ; 
   }


   getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'urls-redirections/get-list',{headers : tokenHeader}) ; 
   }

   getUrlById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'urls-redirections/'+id+'/get-url',{headers : tokenHeader}) ; 
   }

   store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'urls-redirections/store',request,{headers : tokenHeader}) ; 
   }

   update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'urls-redirections/'+id+'/update',request,{headers : tokenHeader}) ; 
   }

   delete(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'urls-redirections/'+id+'/delete',{headers : tokenHeader}) ; 
   }

   get base() {
     let domain = this.baseUrl.split('api') ; 
     return domain[0] ; 
   }


}
