import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TrainingContent } from 'src/app/models/training-content.model';
import { TrainingType } from 'src/app/models/training-type.model';
import { Training } from 'src/app/models/training.model';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingContentsTypesService } from 'src/app/services/trainings/training-contents-types/training-contents-types.service';
import { TrainingsContentsService } from 'src/app/services/trainings/trainings-contents/trainings-contents.service';
import { TrainingsService } from 'src/app/services/trainings/trainings/trainings.service';

@Component({
  selector: 'app-training-contents-edit',
  templateUrl: './training-contents-edit.component.html',
  styleUrls: ['./training-contents-edit.component.scss']
})
export class TrainingContentsEditComponent implements OnInit {

  contentId : string = '' ; 
  content : TrainingContent = null ; 
  training : Training = null ; 
  types : TrainingType[] = null ; 
  _editForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 
  validationErrors : string = null ; 
  generatedSlug : string = '' ; 

  constructor(
    private _activatedRoute : ActivatedRoute ,
    private _router : Router , 
    private _toastr : ToastrService , 
    private _contentsService : TrainingsContentsService , 
    private _typesService : TrainingContentsTypesService,
    private _formBuilder : FormBuilder,
    private _commonService : CommonService , 
    private _trainingsService : TrainingsService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.contentId = params['id'] ; 
        this.subscribeOnGetContentObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetContentObservable() {
    this._contentsService.getContentById(this.contentId).subscribe(
      (result) => {
        if(result.success_code == 404){
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.content = result.content ;
          this.generatedSlug = this.content.slug ; 
          this.subscribeOnGetTypesObservable() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTrainingObservable(id : string) {
    this._trainingsService.getTrainingById(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.training = result.education ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTypesObservable() {
    this._typesService.getList(""+this.content.education_id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.types = result.types ;
          this.subscribeOnGetTrainingObservable(''+this.content.education_id) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      title : [this.content.title,[Validators.required]]  ,
      display_title : [this.content.display_title,[Validators.required]] ,  
      image : [''] ,
      alt_image : [this.content.alt_image] , 
      description : [this.content.description,[Validators.required]] ,  
      education_type_id : [this.content.education_type_id,[Validators.required]] , 
      admission_conditions_left : [this.content.admission_conditions_left,[Validators.required]] , 
      admission_conditions_right : [this.content.admission_conditions_right,[Validators.required]] , 
      program : [this.content.program,[Validators.required]] , 
      training_modality : [this.content.training_modality,[Validators.required]] , 
      training_schedule : [this.content.training_schedule,[Validators.required]] ,
      meta_title : [this.content.meta_title] , 
      meta_description : this.content.meta_description , 
      keywords : [this.content.keywords]
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ;
  }

  onFileChange(event,image) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      let reader = new FileReader();
      //let result = event.target.result ;   
      this._editForm.patchValue({
        image : file ,
      }) ;
      reader.onload = function (fre) { // whatever we want FileReader to do, wee need to do that when it loads
        image.src = reader.result ; 
      }
      reader.readAsDataURL(file);  
      //console.log(image) ; 
         
    }
  }

  onContentUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    const formData = new FormData() ; 

    let title = this.getControlValue('title') ; 
    let display_title = this.getControlValue('display_title') ;
    let image = this.getControlValue('image') ; 
    let description = this.getControlValue('description') ; 
    let admission_conditions_left = this.getControlValue('admission_conditions_left') ; 
    let admission_conditions_right = this.getControlValue('admission_conditions_right') ; 
    let program = this.getControlValue('program') ; 
    let training_modality = this.getControlValue('training_modality') ; 
    let training_schedule = this.getControlValue('training_schedule') ; 
    let education_type_id = this.getControlValue('education_type_id') ; 
    let meta_title = this.getControlValue('meta_title') ; 
    let meta_description = this.getControlValue('meta_description') ; 
    let keywords = this.getControlValue('keywords') ;
    let alt_image = this.getControlValue('alt_image') ; 

    formData.append('title',title) ; 
    formData.append('display_title',display_title) ; 
    formData.append('image',image) ; 
    formData.append('description',description) ; 
    formData.append('admission_conditions_left',admission_conditions_left) ; 
    formData.append('admission_conditions_right',admission_conditions_right) ; 
    formData.append('program',program) ; 
    formData.append('training_modality',training_modality) ; 
    formData.append('training_schedule',training_schedule) ;  
    formData.append('education_type_id',education_type_id) ;
    formData.append('meta_title',meta_title) ; 
    formData.append('meta_description',meta_description) ; 
    formData.append('keywords',keywords) ;
    formData.append('alt_image',alt_image) ; 
     
    this.validationErrors = null ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnContentUpdateObservable(formData,this.contentId) ; 
  }

  subscribeOnContentUpdateObservable(request : any,id : string) {
    this._contentsService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','training-contents',this.content.education_id,'index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        } else if(result.success_code == 400) {
          this.formIsSubmitted = false ; 
          this.validationErrors = result.message ; 
          window.scroll(0,0) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  onFileBrowserOpen(fileBrowser) {
    fileBrowser.click() ; 
  }

  onEditorFileChange(event,fileBrowser,controlName) {
    if(event.target.files.length > 0) {
      const file = event.target.files[0] ;
      const formData = new FormData() ; 
      formData.append('image',file) ;
      this.subscribeOnStoreImageObservable(formData,fileBrowser,controlName) ;  
    }
  }

  subscribeOnStoreImageObservable(request : any,fileBrowser,controlName) {
    this._commonService.storeImage(request).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.appendImageInEditor(result.url,controlName) ;
          fileBrowser.value = '' ; 
        } 
      } , 
      (error) => {
        console.log(error) ;  
      }
    ) ; 
  }

  appendImageInEditor(srcUrl,controlName) {
    let editorValue = this.getControlValue(controlName) ; 
    //let imageDiv = '<img alt="" src="'+srcUrl+'" style="height:329px; width:658px" />' ; 
    let imageDiv = '<img alt="" src="'+srcUrl+'"/>' ; 
    let finalEditorValue = editorValue+imageDiv ; 
    this.getControl(controlName).setValue(finalEditorValue) ; 
  }

  domain() {
    return this._contentsService.siteDomain ; 
  }

  onUrlTitleChange(event) {
    if(event.target.value.trim().length == 0) {
      this.generatedSlug = '' ; 
      return 0 ; 
    } 
    this.getGeneratedSlug(event.target.value) ; 
  }
  getGeneratedSlug(text : string) {
    this._commonService.getSlug(text).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.generatedSlug = result.slug ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
