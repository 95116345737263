import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TrainingContent } from 'src/app/models/training-content.model';
import { Training } from 'src/app/models/training.model';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingsContentsService } from 'src/app/services/trainings/trainings-contents/trainings-contents.service';
import { TrainingsService } from 'src/app/services/trainings/trainings/trainings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-training-contents-index',
  templateUrl: './training-contents-index.component.html',
  styleUrls: ['./training-contents-index.component.scss']
})
export class TrainingContentsIndexComponent implements OnInit {

  trainingId : string = '' ; 
  training : Training = null ; 
  contents : TrainingContent[] = null ;
  searchWord : string = '' ;  

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _trainingsService : TrainingsService , 
    private _contentsService : TrainingsContentsService,
    private _toastr : ToastrService , 
    private _router : Router ,
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.trainingId = params['trainingId'] ;
        this.subscribeOnGetTrainingObservable(); 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTrainingObservable() {
    this._trainingsService.getTrainingById(this.trainingId).subscribe(
      (result) => {
        if(result.success_code == 404) {  
          this._router.navigate(['/home','dashboard']) ;
          this._toastr.error(result.message) ;  
        } else if(result.success_code == 200) {
          this.training = result.education ;
          this.subscribeOnGetContentsObservable() ;  
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetContentsObservable() {
    this._contentsService.getList(this.trainingId).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.contents = result.contents ; 
          //console.log(this.contents) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray()  {
    return this.searchWord == '' ? this.contents : this._commonService.filterArrayByTitleName(this.searchWord,this.contents,'display_title') ; 
  }

  onCopy() {
    this._toastr.success('Lien copié !') ;  
  }

  onContentDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cette formation ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let content = this.contents.find(
          (ct) => {
            return ct.id == +id ; 
          }
        ) ; 
        this.contents.splice(this.contents.indexOf(content),1) ;
        this.subscribeOnContentDeleteObservable(id) ; 
      }
    });
  }

  subscribeOnContentDeleteObservable(id : string) {
    this._contentsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      }, 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
