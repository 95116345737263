import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CtContentsCategoriesService } from 'src/app/services/ct-contents-categories/ct-contents-categories.service';
import { CustomType } from 'src/app/models/custom-type.model';
import { CustomTypesService } from 'src/app/services/custom-types/custom-types.service';

@Component({
  selector: 'app-ct-contents-categories-create',
  templateUrl: './ct-contents-categories-create.component.html',
  styleUrls: ['./ct-contents-categories-create.component.scss']
})
export class CtContentsCategoriesCreateComponent implements OnInit {

  private _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ;
  customTypeId : string = '' ; 
  customType : CustomType = null ;
  constructor(
    private _formBuilder : FormBuilder,
    private _categoriesService : CtContentsCategoriesService,
    private _toastr : ToastrService,
    private _router : Router,
    private _activatedRoute : ActivatedRoute,
    private _customTypesService : CustomTypesService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.customTypeId = params['id'] ; 
        //this.initCreateForm() ;
        this.subscribeOnGetCustomTypeObservable() ;
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetCustomTypeObservable() {
    this._customTypesService.getCustomTypeById(this.customTypeId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','custom-types','index']) ; 
          this._toastr.error(result.message); 
        }else if(result.success_code == 200) {
          this.customType = result.custom_type ; 
          this.initCreateForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    )
  }

  initCreateForm() {
    let group = {
      label : ['',[Validators.required]] , 
      custom_type_id : [this.customTypeId,[Validators.required]]
    } ; 
    this._createForm = this._formBuilder.group(group) ;
  }

  getControl(controlName : string) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onCategoryCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      label : this.getControlValue('label') ,
      custom_type_id : this.getControlValue('custom_type_id')
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnStoreCategoryObservable(request) ; 
  }

  subscribeOnStoreCategoryObservable(request: any) {
    this._categoriesService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','ct-contents-categories',this.customTypeId,'index']) ;
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ;  
        }
      }, 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
