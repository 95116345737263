import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HeadingItem } from 'src/app/models/heading-item.model';
import { Heading } from 'src/app/models/heading.model';
import { CommonService } from 'src/app/services/common/common.service';
import { HeadingItemsService } from 'src/app/services/heading-items/heading-items.service';
import { HeadingsService } from 'src/app/services/headings/headings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-heading-items-index',
  templateUrl: './heading-items-index.component.html',
  styleUrls: ['./heading-items-index.component.scss']
})
export class HeadingItemsIndexComponent implements OnInit {

  headingId : string = '' ; 
  heading : Heading = null ; 
  items : HeadingItem[] = null ;
  searchWord : string = '' ;  

  constructor(
    private _activatedRoute : ActivatedRoute ,
    private _headingsService : HeadingsService ,
    private _itemsService : HeadingItemsService , 
    private _toastr : ToastrService , 
    private _router : Router , 
    private _commonService : CommonService
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.headingId = params['headingId'] ; 
        this.subscribeOnGetHeadingObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetHeadingObservable() {
    this._headingsService.getHeadingById(this.headingId).subscribe(
        (result) => {
          if(result.success_code == 200) {
            this.heading = result.heading ;
            this.subscribeOnGetItemsObservable() ;  
          } else if(result.success_code == 404) {
            this._router.navigate(['/home','headings','index']) ; 
            this._toastr.error(result.message) ; 
          }
        } , 
        (error) => {
          console.log(error) ; 
        }
    ) ; 
  }

  subscribeOnGetItemsObservable() {
    this._itemsService.getList(this.headingId).subscribe(
      (result) => {
        if(result.success_code == 200 ) {
          this.items = result.items ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredArray() {
    return this.searchWord == '' ? this.items : this._commonService.filterArrayByTitleName(this.searchWord,this.items,'title') ; 
  }

  onItemDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cet item ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let item = this.items.find(
          (i) => {
            return i.id == +id ; 
          }
        ) ; 
        this.items.splice(this.items.indexOf(item),1) ;
        this.subscribeOnDeleteItemObservable(id) ; 
      }
    });
  }

  subscribeOnDeleteItemObservable(id : string) {
    this._itemsService.delete(id).subscribe(
      (result) => {
        (result.success_code == 200) && this._toastr.success(result.message) ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
