import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {

  protected baseUrl = "http://127.0.0.1:8005/api/"  ;
  //protected baseUrl = "https://backend.plateforme-enw.com/api/" ;
  // protected baseUrl = "https://backend.plateforme-enw.com/P1-ISCG/public/api/" ;


  constructor() { }

  get siteDomain() {
    let domain = this.baseUrl.split('api') ;
    return domain[0] ;
  }
}
