import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Heading } from 'src/app/models/heading.model';
import { CommonService } from 'src/app/services/common/common.service';
import { HeadingsService } from 'src/app/services/headings/headings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-headings-index',
  templateUrl: './headings-index.component.html',
  styleUrls: ['./headings-index.component.scss']
})
export class HeadingsIndexComponent implements OnInit {

  headings : Heading[] = null ; 
  searchWord : string = '' ; 

  constructor(
    private _headingsService : HeadingsService,
    private _commonService : CommonService,
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
    this.subscribeOnGetHeadingsObservable() ; 
  }

  subscribeOnGetHeadingsObservable() {
    this._headingsService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.headings = result.headings ; 
          //console.log(this.headings) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    )
  }

  filteredArray() {
    return this.searchWord == '' ? this.headings : this._commonService.filterArrayByTitleName(this.searchWord,this.headings,'display_title') ; 
  }

  onCopy() {
    this._toastr.success('Lien copié !') ; 
  }

  onHeadingDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cette rubrique ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let heading = this.headings.find(
          (head) => {
            return head.id == +id ; 
          }
        ) ; 
        this.headings.splice(this.headings.indexOf(heading),1) ;
        this.subscribeOnDeleteHeadingObservable(id) ; 
      }
    });
  }

  subscribeOnDeleteHeadingObservable(id : string) {
    this._headingsService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
