import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrainingsService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ;
   }

   getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'trainings/get-list',{headers : tokenHeader}) ; 
  }

  getTrainingById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'trainings/'+id+'/get-training',{headers : tokenHeader}) ; 
  }

  update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.put<any>(this.baseUrl+'trainings/'+id+'/update',request,{headers : tokenHeader}) ;
  }
  
}
