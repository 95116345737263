import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TrainingType } from 'src/app/models/training-type.model';
import { TrainingContentsTypesService } from 'src/app/services/trainings/training-contents-types/training-contents-types.service';

@Component({
  selector: 'app-trainings-contents-types-edit',
  templateUrl: './trainings-contents-types-edit.component.html',
  styleUrls: ['./trainings-contents-types-edit.component.scss']
})
export class TrainingsContentsTypesEditComponent implements OnInit {

  typeId : string = '' ; 
  type : TrainingType = null ;
  _editForm : FormGroup ;
  formIsSubmitted : boolean = false ; 

  constructor(
    private _activatedRoute : ActivatedRoute , 
    private _formBuilder : FormBuilder,
    private _typesService : TrainingContentsTypesService,
    private _toastr : ToastrService,
    private _router : Router
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.typeId = params['id'] ; 
        this.subscribeOnGetTypeObservable() ; 
      }, 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetTypeObservable() {
    this._typesService.getTypeById(this.typeId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','dashboard']) ; 
          this._toastr.error(result.message) ; 
        } else if(result.success_code == 200) {
          this.type = result.type ; 
          this.initEditForm() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initEditForm() {
    let group = {
      label : [this.type.label,[Validators.required]] , 
    } ; 
    this._editForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._editForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ;
  }

  onTypeUpdate() {
    if(this._editForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let request = {
      label : this.getControlValue('label')
    } ;
    this.formIsSubmitted = true ; 
    this.subscribeOnTypeUpdateObservable(request,this.typeId) ;  
  }

  subscribeOnTypeUpdateObservable(request : any , id : string) {
    this._typesService.update(request,id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','trainings-contents-types',this.type.education_id,'index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }



}
