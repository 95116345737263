import { Component, OnInit } from '@angular/core';
import { CustomTypesContentsService } from 'src/app/services/custom-types-contents/custom-types-contents.service';
import { ToastrService } from 'ngx-toastr';
import { CustomType } from 'src/app/models/custom-type.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTypesService } from 'src/app/services/custom-types/custom-types.service';
import { CustomTypeContent } from 'src/app/models/custom-type-content.model';
import { CommonService } from 'src/app/services/common/common.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-custom-type-content-index',
  templateUrl: './custom-type-content-index.component.html',
  styleUrls: ['./custom-type-content-index.component.scss']
})
export class CustomTypeContentIndexComponent implements OnInit {

  customType : CustomType = null ; 
  customTypeId : string = '' ; 
  contents : CustomTypeContent[] = null ;
  searchWord : string = '' ;  

  constructor(
    private _contentsService : CustomTypesContentsService,
    private _toastr : ToastrService,
    private _activatedRoute : ActivatedRoute,
    private _router : Router,
    private _commonService : CommonService
    
  ) { }

  ngOnInit() {
    this.subscribeOnActivatedRouteObservable() ; 
  }

  subscribeOnActivatedRouteObservable() {
    this._activatedRoute.params.subscribe(
      (params) => {
        this.customTypeId = params['id'] ; 
        this.subscribeOnGetContentsObservable() ; 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  subscribeOnGetContentsObservable() {
    this._contentsService.getList(this.customTypeId).subscribe(
      (result) => {
        if(result.success_code == 404) {
          this._router.navigate(['/home','custom-types','index']) ;
          this._toastr.error(result.message) ; 

        } else if(result.success_code == 200) {
          this.contents = result.contents ; 
          this.customType = result.customType ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredContentsArray() {
    //return this._commonService.filterContentsArray(this.searchWord,this.contents) ; 
    return this.searchWord == '' ? this.contents : this._commonService.filterArrayByTitleName(this.searchWord,this.contents,'display_title') ;
  }

onCopy() {
  this._toastr.success('Lien copié !') ; 
}

onContentDelete(id : string) {
  swal.fire({
    title: 'Confirmation',
    text: "Etes-vous sûr de supprimer ce contenu ?",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui',
    cancelButtonText : 'Annuler'
  }).then((result) => {
    if (result.value) {
      let content = this.contents.find(
        (cont) => {
          return cont.id == +id ; 
        }
      ) ; 
      this.contents.splice(this.contents.indexOf(content),1) ;
      this.contents = null ; 
      this.searchWord = '' ; 
      this.subscribeOnContentDeleteObservable(id) ; 
    }
  });
}

subscribeOnContentDeleteObservable(id : string) {
  this._contentsService.delete(id).subscribe(
    (result) => {
      if(result.success_code == 200) {
        this.contents = result.contents ; 
        this._toastr.success(result.message) ; 
      }
    } , 
    (error) => {
      console.log(error) ; 
    }
  ) ; 
}

}
