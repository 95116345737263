import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { PagesIndexComponent } from './pages/pages-index/pages-index.component';
import { PagesCreateComponent } from './pages/pages-create/pages-create.component';
import { PagesEditComponent } from './pages/pages-edit/pages-edit.component';
import { PagesCategoriesIndexComponent } from './pages-categories/pages-categories-index/pages-categories-index.component';
import { PagesCategoriesCreateComponent } from './pages-categories/pages-categories-create/pages-categories-create.component';
import { PagesCategoriesEditComponent } from './pages-categories/pages-categories-edit/pages-categories-edit.component';
import { CustomTypesIndexComponent } from './custom-types/custom-types-index/custom-types-index.component';
import { CustomTypeCreateComponent } from './custom-types/custom-type-create/custom-type-create.component';
import { CustomTypeEditComponent } from './custom-types/custom-type-edit/custom-type-edit.component';
import { CustomTypesCategoriesIndexComponent } from './custom-types-categories/custom-types-categories-index/custom-types-categories-index.component';
import { CustomTypesCategoriesCreateComponent } from './custom-types-categories/custom-types-categories-create/custom-types-categories-create.component';
import { CustomTypesCategoriesEditComponent } from './custom-types-categories/custom-types-categories-edit/custom-types-categories-edit.component';
import { AuthGuard } from './services/guards/auth/auth.guard';
import { GuestGuard } from './services/guards/guest/guest.guard';
import { SliderIndexComponent } from './home-page/slider/slider-index/slider-index.component';
import { SliderEditComponent } from './home-page/slider/slider-edit/slider-edit.component';
import { TrainingsIndexComponent } from './home-page/trainings/trainings-index/trainings-index.component';
import { TrainingEditComponent } from './home-page/trainings/training-edit/training-edit.component';
import { CustomTypeContentIndexComponent } from './custom-type-content/custom-type-content-index/custom-type-content-index.component';
import { CustomTypeContentCreateComponent } from './custom-type-content/custom-type-content-create/custom-type-content-create.component';
import { TrainingTypesIndexComponent } from './home-page/training-types/training-types-index/training-types-index.component';
import { TrainingTypesEditComponent } from './home-page/training-types/training-types-edit/training-types-edit.component';
import { PedagogiesIndexComponent } from './home-page/pedagogy/pedagogies-index/pedagogies-index.component';
import { PedagogiesEditComponent } from './home-page/pedagogy/pedagogies-edit/pedagogies-edit.component';
import { InternationalIndexComponent } from './home-page/international/international-index/international-index.component';
import { InternationalEditComponent } from './home-page/international/international-edit/international-edit.component';
import { CtContentsCategoriesIndexComponent } from './ct-contents-categories/ct-contents-categories-index/ct-contents-categories-index.component';
import { CtContentsCategoriesCreateComponent } from './ct-contents-categories/ct-contents-categories-create/ct-contents-categories-create.component';
import { CtContentsCategoriesEditComponent } from './ct-contents-categories/ct-contents-categories-edit/ct-contents-categories-edit.component';
import { CustomTypeContentEditComponent } from './custom-type-content/custom-type-content-edit/custom-type-content-edit.component';
import { FooterBlocsIndexComponent } from './home-page/footer/footer-blocs/footer-blocs-index/footer-blocs-index.component';
import { FooterBlocsCreateComponent } from './home-page/footer/footer-blocs/footer-blocs-create/footer-blocs-create.component';
import { FooterBlocsEditComponent } from './home-page/footer/footer-blocs/footer-blocs-edit/footer-blocs-edit.component';
import { TopMenuElementsIndexComponent } from './home-page/menus/top-menu/top-menu-elements-index/top-menu-elements-index.component';
import { TopMenuElementsEditComponent } from './home-page/menus/top-menu/top-menu-elements-edit/top-menu-elements-edit.component';
import { FooterElementsIndexComponent } from './home-page/footer/footer-elements/footer-elements-index/footer-elements-index.component';
import { FooterElementsCreateComponent } from './home-page/footer/footer-elements/footer-elements-create/footer-elements-create.component';
import { FooterElementsEditComponent } from './home-page/footer/footer-elements/footer-elements-edit/footer-elements-edit.component';
import { SocialMediaIndexComponent } from './home-page/social-media/social-media-index/social-media-index.component';
import { SocialMediaEditComponent } from './home-page/social-media/social-media-edit/social-media-edit.component';
import { NewsLettersIndexComponent } from './home-page/news-letters/news-letters-index/news-letters-index.component';
import { MenusIndexComponent } from './menus/menus-index/menus-index.component';
import { MenusCreateComponent } from './menus/menus-create/menus-create.component';
import { MenusEditComponent } from './menus/menus-edit/menus-edit.component';
import { FaqIndexComponent } from './faq/faq-index/faq-index.component';
import { FaqCreateComponent } from './faq/faq-create/faq-create.component';
import { FaqEditComponent } from './faq/faq-edit/faq-edit.component';
import { TrainingsContentsTypesIndexComponent } from './trainings/training-contents-types/trainings-contents-types-index/trainings-contents-types-index.component';
import { TrainingsContentsTypesCreateComponent } from './trainings/training-contents-types/trainings-contents-types-create/trainings-contents-types-create.component';
import { TrainingsContentsTypesEditComponent } from './trainings/training-contents-types/trainings-contents-types-edit/trainings-contents-types-edit.component';
import { TrainingPresentationEditComponent } from './trainings/training-presentation/training-presentation-edit/training-presentation-edit.component';
import { TrainingContentsIndexComponent } from './trainings/training-contents/training-contents-index/training-contents-index.component';
import { TrainingContentsCreateComponent } from './trainings/training-contents/training-contents-create/training-contents-create.component';
import { TrainingContentsEditComponent } from './trainings/training-contents/training-contents-edit/training-contents-edit.component';
import { HeadingsIndexComponent } from './headings/headings-index/headings-index.component';
import { HeadingsCreateComponent } from './headings/headings-create/headings-create.component';
import { HeadingsEditComponent } from './headings/headings-edit/headings-edit.component';
import { HeadingItemsIndexComponent } from './heading-items/heading-items-index/heading-items-index.component';
import { HeadingItemsCreateComponent } from './heading-items/heading-items-create/heading-items-create.component';
import { HeadingItemsEditComponent } from './heading-items/heading-items-edit/heading-items-edit.component';
import { NotificationEmailsIndexComponent } from './notification-emails/notification-emails-index/notification-emails-index.component';
import { NotificationEmailsCreateComponent } from './notification-emails/notification-emails-create/notification-emails-create.component';
import { NotificationEmailsEditComponent } from './notification-emails/notification-emails-edit/notification-emails-edit.component';
import { MailResponsesEditComponent } from './mails-responses/mail-responses-edit/mail-responses-edit.component';
import { SmtpEditComponent } from './smtp/smtp-edit/smtp-edit.component';
import { HomePageMetaEditComponent } from './home-page/home-page-meta/home-page-meta-edit/home-page-meta-edit.component';
import { PreregistrationsIndexComponent } from './pre-registrations/preregistrations-index/preregistrations-index.component';
import { CallbackRequestsIndexComponent } from './callback-requests/callback-requests-index/callback-requests-index.component';
import { ProfileComponent } from './profile/profile.component';
import { UrlRedirectionsIndexComponent } from './url-redirections/url-redirections-index/url-redirections-index.component';
import { UrlRedirectionsCreateComponent } from './url-redirections/url-redirections-create/url-redirections-create.component';
import { UrlRedirectionsEditComponent } from './url-redirections/url-redirections-edit/url-redirections-edit.component';


const routes: Routes = [
  {path : '' , redirectTo:'home',pathMatch : 'full'} ,

  {path : 'auth'  ,children : [
    {path : '' , redirectTo : 'login' , pathMatch : 'full'} , 
    {path : 'login' ,canActivate : [GuestGuard],  component : LoginComponent} , 
  ]} ,

  {path:'home',  canActivate : [AuthGuard] ,children : [
    
    {path : '' , redirectTo : 'dashboard' , pathMatch : 'full'},
    {path : 'dashboard' , component : HomeComponent } ,
    {path : 'pages'  , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'},
      {path : 'index' , component : PagesIndexComponent} , 
      {path : 'create' , component : PagesCreateComponent},
      {path : ':id/edit' , component : PagesEditComponent} , 
    ]},
    {path : 'pages-categories' , children : [
      {path : '' , redirectTo : 'index' , 'pathMatch' : 'full'} , 
      {path : 'index' , component : PagesCategoriesIndexComponent} , 
      {path : 'create' , component : PagesCategoriesCreateComponent} ,
      {path : ':id/edit' , component : PagesCategoriesEditComponent} , 
    ]} ,
    {path : 'custom-types' , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'} ,
      {path : 'index' , component : CustomTypesIndexComponent} ,  
      {path : 'create' , component : CustomTypeCreateComponent} ,
      {path : ':id/edit' , component : CustomTypeEditComponent},
    ]} ,
    {path : 'custom-type-content',children : [
      {path : ':id/index' , component : CustomTypeContentIndexComponent},
      {path : ':id/create', component : CustomTypeContentCreateComponent},
      {path : ':id/edit' , component : CustomTypeContentEditComponent}
    ]},
    /*{path : 'custom-types-categories', children :[
      {path : '', redirectTo : 'index', pathMatch : 'full'} , 
      {path : 'index' , component : CustomTypesCategoriesIndexComponent},
      {path : 'create' , component : CustomTypesCategoriesCreateComponent},
      {path : ':id/edit' , component : CustomTypesCategoriesEditComponent} ,
    ]} , */
    {path : 'ct-contents-categories' , children : [
      {path : ':id/index', component : CtContentsCategoriesIndexComponent},
      {path : ':id/create' , component : CtContentsCategoriesCreateComponent},
      {path : ':id/edit' , component : CtContentsCategoriesEditComponent}
    ]} ,
    {path : 'menus' , children : [
      {path : '' , redirectTo : 'index',pathMatch:'full'} , 
      {path : 'index', component : MenusIndexComponent} , 
      {path : 'create' , component : MenusCreateComponent} , 
      {path : ':id/edit' , component : MenusEditComponent}
    ]},
    {path : 'faqs' , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
      {path : 'index' , component : FaqIndexComponent} , 
      {path : 'create' , component : FaqCreateComponent} , 
      {path : ':id/edit' , component : FaqEditComponent}
    ]} ,
    {path : 'trainings-contents-types' , children : [
      {path : ':trainingId/index' , component : TrainingsContentsTypesIndexComponent } , 
      {path : ':trainingId/create' , component : TrainingsContentsTypesCreateComponent} , 
      {path : ':id/edit' , component : TrainingsContentsTypesEditComponent}
    ]} ,
    {path : 'training-presentation/:trainingId/edit',component : TrainingPresentationEditComponent} ,
    {path : 'training-contents' , children : [
      {path : ':trainingId/index' , component : TrainingContentsIndexComponent} , 
      {path : ':trainingId/create' , component : TrainingContentsCreateComponent}, 
      {path : ':id/edit' , component : TrainingContentsEditComponent}
    ]} ,
    {path : 'headings' , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
      {path : 'index' , component : HeadingsIndexComponent} , 
      {path : 'create' , component : HeadingsCreateComponent} , 
      {path : ':id/edit' , component : HeadingsEditComponent}
    ]},
    {path : 'heading-items' , children : [
      {path : ':headingId/index' , component : HeadingItemsIndexComponent} ,
      {path : ':headingId/create' , component : HeadingItemsCreateComponent} , 
      {path : ':id/edit' , component : HeadingItemsEditComponent} , 
    ]},
    {path : 'notification-emails' , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
      {path : 'index' , component : NotificationEmailsIndexComponent} , 
      {path : 'create' , component : NotificationEmailsCreateComponent} , 
      {path : ':id/edit' , component : NotificationEmailsEditComponent},
    ]},
    {path : 'mails-responses' , children : [
      {path : ':id/edit' , component : MailResponsesEditComponent}
    ]} , 
    {path : 'smtp/:id/edit' , component : SmtpEditComponent} ,
    {path : 'registrations' , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
      {path : 'index' , component : PreregistrationsIndexComponent} , 
    ]} ,
    {path : 'callback-requests' , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
      {path : 'index' , component : CallbackRequestsIndexComponent}
    ]},
    {path : 'profile' , component : ProfileComponent} ,
    {path : 'url-redirections' , children : [
      {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
      {path : 'index' , component : UrlRedirectionsIndexComponent} , 
      {path : 'create' , component : UrlRedirectionsCreateComponent} , 
      {path : ':id/edit' , component : UrlRedirectionsEditComponent} , 
    ]} ,  
    {path : 'home-page' , children : [

      {path : 'slider' ,children : [
        {path : '' , redirectTo :  'index' , pathMatch: 'full'} , 
        {path : 'index' , component : SliderIndexComponent},
        {path : ':id/edit' , component: SliderEditComponent}
      ]} ,
      {path : 'trainings' , children : [
        {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
        {path : 'index', component : TrainingsIndexComponent} , 
        {path : ':id/edit' ,component : TrainingEditComponent}
      ]} , 
      {path : 'training-types' , children : [
        {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
        {path : 'index' , component : TrainingTypesIndexComponent} , 
        {path : ':id/edit' , component : TrainingTypesEditComponent},
      ]} ,
      {path : 'pedagogies', children : [
        {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
        {path : 'index' , component : PedagogiesIndexComponent} , 
        {path : ':id/edit', component : PedagogiesEditComponent},
      ]} , 
      {path : 'international' , children : [
        {path : '' , redirectTo:'index' , pathMatch : 'full'} , 
        {path : 'index' , component : InternationalIndexComponent} , 
        {path : ':id/edit' , component : InternationalEditComponent} ,

      ]} , 
      {path : 'footer-blocs' , children : [
        {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
        {path : 'index' , component : FooterBlocsIndexComponent} ,
        {path : 'create' , component : FooterBlocsCreateComponent},
        {path : ':id/edit' , component : FooterBlocsEditComponent} 
      ]} ,
      {path : 'footer-elements' , children : [
        {path : ':blocId/index' , component : FooterElementsIndexComponent} , 
        {path : ':blocId/create' , component : FooterElementsCreateComponent} , 
        {path : ':id/edit' , component : FooterElementsEditComponent},
      ]} , 
      {path : 'top-menu-elements',children : [
        {path : '' , redirectTo : 'index' ,pathMatch : 'full'} , 
        {path : 'index' , component : TopMenuElementsIndexComponent} , 
        {path : ':id/edit' , component : TopMenuElementsEditComponent }
      ]} ,
      {path : 'social-media' , children : [
        {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
        {path : 'index' , component : SocialMediaIndexComponent} ,
        {path : ':id/edit' , component : SocialMediaEditComponent}
      ]} ,
      {path : 'news-letters' , children  : [
        {path : '' , redirectTo : 'index' , pathMatch : 'full'} , 
        {path : 'index' , component : NewsLettersIndexComponent} , 
      ]} ,
      {path : 'meta/:id/edit' , component : HomePageMetaEditComponent}      
    ]} ,  
  ]}, 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
}) 
export class AppRoutingModule { }
