import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PedagogiesService extends BaseService {

  constructor(private _http: HttpClient) { 
    super() ;
  }

  getList() {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'pedagogies/get-list',{headers : tokenHeader}) ; 
  }

  getPedagogyById(id:string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'pedagogies/'+id+'/get-pedagogy',{headers : tokenHeader}) ;
  }

  update(request : any , id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'pedagogies/'+id+'/update',request,{headers : tokenHeader}) ; 
  }
}
