import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingsContentsService extends BaseService {

  constructor(private _http : HttpClient) {
    super() ; 
   }

   getList(trainingId : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'educations-contents/'+trainingId+'/get-list',{headers : tokenHeader}) ; 
   }

   getContentById(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.get<any>(this.baseUrl+'educations-contents/'+id+'/get-content',{headers : tokenHeader}) ; 
   }

   store(request : any) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'educations-contents/store',request,{headers : tokenHeader}) ; 
   }

   update(request : any,id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.post<any>(this.baseUrl+'educations-contents/'+id+'/update',request,{headers : tokenHeader}) ; 
   }

   delete(id : string) {
    let tokenHeader :  HttpHeaders = new HttpHeaders({'Authorization' : 'Bearer '+localStorage.getItem('token')}) ;
    return this._http.delete<any>(this.baseUrl+'educations-contents/'+id+'/delete',{headers : tokenHeader}) ; 
   }
}
