import {Component, QueryList, ViewChildren,OnInit} from '@angular/core';
import {Page} from '../../models/page.model';
import {NgbdSortableHeader, SortEvent} from '../../directives/sortable.directive';
import { CommonService } from 'src/app/services/common/common.service';
import { PagesService } from 'src/app/services/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Component({
  selector: 'app-pages-index',
  templateUrl: './pages-index.component.html',
  styleUrls: ['./pages-index.component.scss']
})
export class PagesIndexComponent implements OnInit {

  pages : Page[] = null; 
  searchWord : string = '' ; 

  constructor(
    private _commonService : CommonService , 
    private _pagesService : PagesService , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() {
     this.subscribeOnGetPagesObservable() ; 
  }

  subscribeOnGetPagesObservable() {
    this._pagesService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.pages = result.pages ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  filteredPagesArray() {
    return this._commonService.filterArrayByTitleName(this.searchWord,this.pages,'display_title') ; 
  }

  onCopy() {
    this._toastr.success('Lien copié !') ; 
  }

  onDelete(id : string) {
    swal.fire({
      title: 'Confirmation',
      text: "Etes-vous sûr de supprimer cette page ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText : 'Annuler'
    }).then((result) => {
      if (result.value) {
        let page = this.pages.find(
          (p) => {
            return p.id == +id ; 
          }
        ) ; 
        this.pages.splice(this.pages.indexOf(page),1) ;
        this.pages = null ; 
        this.searchWord = '' ; 
        this.subscribeOnDeletePageObservable(id) ; 
      }
    });
  }

  subscribeOnDeletePageObservable(id : string) {
    this._pagesService.delete(id).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.pages = result.pages ; 
          this._toastr.success(result.message) ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }



}
