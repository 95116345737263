import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UrlsRedirectionsService } from 'src/app/services/urls-redirections/urls-redirections.service';

@Component({
  selector: 'app-url-redirections-create',
  templateUrl: './url-redirections-create.component.html',
  styleUrls: ['./url-redirections-create.component.scss']
})
export class UrlRedirectionsCreateComponent implements OnInit {

  _createForm : FormGroup ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _redirectionsService : UrlsRedirectionsService , 
    private _formBuilder : FormBuilder , 
    private _router : Router , 
    private _toastr : ToastrService,
  ) { }

  ngOnInit() {
    this.initCreateForm() ; 
    
  }

  initCreateForm() {
    let group = {
      old_url : ['',[Validators.required]] , 
      new_url : ['',[Validators.required]] , 
    } ; 
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }

  onUrlCreate() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    if(!this.getControlValue('old_url').startsWith(this._redirectionsService.base) || !this.getControlValue('new_url').startsWith(this._redirectionsService.base)) {
      this._toastr.error('Les 2 urls doivent commencer par le domaine de votre site') ;  
      return 0 ; 
    }
    if(this.getControlValue('old_url').endsWith('/') || this.getControlValue('new_url').endsWith('/')) {
      /* if(this.getControlValue('old_url') != this._redirectionsService.base && this.getControlValue('new_url') != this._redirectionsService.base) {
        this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
        return 0 ;
      } */
      if(this.getControlValue('old_url') == this._redirectionsService.base && this.getControlValue('new_url') != this._redirectionsService.base) {
        if(this.getControlValue('new_url').endsWith('/')) {
          this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
          return 0 ;
        }
      } else if(this.getControlValue('new_url') == this._redirectionsService.base && this.getControlValue('old_url') != this._redirectionsService.base) {
        if(this.getControlValue('old_url').endsWith('/')) {
          this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
          return 0 ;
        }
      }  else if(this.getControlValue('old_url') != this._redirectionsService.base && this.getControlValue('new_url') != this._redirectionsService.base) {
          if(this.getControlValue('old_url').endsWith('/') || this.getControlValue('new_url').endsWith('/')) {
            this._toastr.error('Les urls ne doivent pas se terminer par un /') ; 
            return 0 ;
          }
      }
    }
    let request = {
      old_url : this.getControlValue('old_url')  , 
      new_url : this.getControlValue('new_url') , 
    } ; 
    this.formIsSubmitted = true ; 
    this.subscribeOnUrlCreateObservable(request) ; 
  }

  subscribeOnUrlCreateObservable(request) {
    this._redirectionsService.store(request).subscribe(
      (result)=> {
        if(result.success_code == 200) {
          this._router.navigate(['/home','url-redirections','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        } 
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

}
