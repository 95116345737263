import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Menu } from 'src/app/models/menu.model';
import { MenusService } from 'src/app/services/menus/menus.service';

declare function initSelect2() : any ;

@Component({
  selector: 'app-menus-create',
  templateUrl: './menus-create.component.html',
  styleUrls: ['./menus-create.component.scss']
})
export class MenusCreateComponent implements OnInit {

  _createForm : FormGroup ; 
  menus : Menu[] = null ; 
  formIsSubmitted : boolean = false ; 

  constructor(
    private _formBuilder : FormBuilder , 
    private _menusService : MenusService , 
    private _router : Router , 
    private _toastr : ToastrService
  ) { }

  ngOnInit() { 
    this.subscribeOnGetMenusObservable() ; 
  }

  subscribeOnGetMenusObservable() {
    this._menusService.getList().subscribe(
      (result) => {
        if(result.success_code == 200) {
          this.menus = result.menus ;  
          this.initCreateForm() ;
          initSelect2() ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }

  initCreateForm() {
    let group = {
      title : ['',[Validators.required]] , 
      url : [''] , 
      parent_id : [0] ,
      //rank : ['',[Validators.required]] 
    } ; 
    this._createForm = this._formBuilder.group(group) ; 
  }

  getControl(controlName) {
    return this._createForm.get(controlName) ; 
  }

  getControlValue(controlName) {
    return this.getControl(controlName).value ; 
  }
  
  onElementStore() {
    if(this._createForm.invalid) {
      this._toastr.error('Formulaire invalide !') ; 
      return 0 ; 
    }
    let numberOfParents = 0 ; 
    for(let element of this.menus) {(element.parent_id == null ) && numberOfParents ++ ;}
    if(numberOfParents > 6 && this.getControlValue('parent_id') == 0) {
      this._router.navigate(['/home','menus','index']) ; 
      this._toastr.error('Vous ne pouvez pas dépasser 7 éléments parents ') ; 
      return 0 ; 
    }
    this.formIsSubmitted = true ;  
    let request = {
      title : this.getControlValue('title') , 
      parent_id : this.getControlValue('parent_id') , 
      url : this.getControlValue('url')  
    } ; 
    this.subscribeOnElementStoreObservable(request) ; 
  }

  subscribeOnElementStoreObservable(request : any) {
    this._menusService.store(request).subscribe(
      (result) => {
        if(result.success_code == 200) {
          this._router.navigate(['/home','menus','index']) ; 
          this._toastr.success(result.message) ; 
          this.formIsSubmitted = false ; 
        }
      } , 
      (error) => {
        console.log(error) ; 
      }
    ) ; 
  }




}
